import React from "react";
import SideBar from "../components/SideBar";
import Header from "../components/Header";

const MyShopPage = () => {
  return (
    <div>
      <Header />
      <SideBar />
    </div>
  );
};

export default MyShopPage;
