import React from "react";
import Header from "../components/Header";
import SideBar from "../components/SideBar";

const ShopQrPage = () => {
  return (
    <div>
      <Header />
      <SideBar />
    </div>
  );
};

export default ShopQrPage;
