import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./slices/cartSlice";
import userSlice from "./slices/userSlice";
import bluetoothSlice from "./slices/bluetoothSlice";
import printerDevicesSlice from "./slices/printerDevicesSlice";
// import mainSlice from "./slices/mainSlice";

export default configureStore({
  reducer: {
    cart: cartSlice,
    // main: mainSlice,
    user: userSlice,
    // Other reducers...
    bluetooth: bluetoothSlice,
    printerDevices: printerDevicesSlice,
  },
});
