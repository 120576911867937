import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { db } from "../firebase";
import { List, Pagination, Box, CircularProgress } from "@mui/material";
import { getAuth } from "firebase/auth";
import { format } from "date-fns";
const PAGE_SIZE = 10;
const ShopOrders = () => {
  const uid = getAuth().currentUser.uid;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastDocs, setLastDocs] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    if (uid) {
      fetchTotalPages();
      fetchOrderDetails(1);
    }
  }, [uid]);

  const fetchTotalPages = async () => {
    const serverItemsCollection = doc(db, "shop", uid);
    const shopItemref = collection(serverItemsCollection, "shopOrders");
    const q = query(shopItemref, orderBy("orderDate", "desc"));
    const snapshot = await getDocs(q);
    const totalItems = snapshot.size;
    setTotalPages(Math.ceil(totalItems / PAGE_SIZE));
  };

  const fetchOrderDetails = async (pageNumber) => {
    setLoading(true);
    if (uid) {
      const serverItemsCollection = doc(db, "shop", uid);
      const shopItemref = collection(serverItemsCollection, "shopOrders");
      let q = query(
        shopItemref,
        orderBy("orderDate", "desc"),
        limit(PAGE_SIZE)
      );

      if (pageNumber > 1) {
        if (lastDocs[pageNumber - 1]) {
          q = query(q, startAfter(lastDocs[pageNumber - 1]));
        } else {
          const lastVisible = await fetchLastDocForPage(pageNumber);
          q = query(q, startAfter(lastVisible));
        }
      }

      const querySnapshot = await getDocs(q);
      const items = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Update the lastDocs dictionary to store the last document for this page
      setLastDocs((prevLastDocs) => ({
        ...prevLastDocs,
        [pageNumber]: querySnapshot.docs[querySnapshot.docs.length - 1],
      }));

      setData(items);
    }
    setLoading(false);
  };

  const fetchLastDocForPage = async (pageNumber) => {};

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchOrderDetails(value);
  };
  return (
    <div>
      <Header />
      <div className="flex">
        <SideBar />
        <div className="w-[95%]">
          {/* <div className="container m-auto mt-2">
            <input
              placeholder="Search by name"
              className="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none"
            />
          </div> */}
          <Box p="5">
            <div className="w-2/3">
              <div className="shadow-lg m-3">
                <div className="bg-white border-b px-4 py-3">
                  <div className="flex justify-between items-center">
                    <h4 className="text-xl font-bold">
                      <b>Orders</b>
                    </h4>
                    <div>{/* <DateRangeInput /> */}</div>
                  </div>
                </div>
                <div
                  className="bg-white p-4 overflow-y-auto"
                  style={{ maxHeight: "65vh" }}
                >
                  {loading ? (
                    <div className="flex justify-center items-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    data.map((order, index) => (
                      <div
                        key={index}
                        className="bg-gray-100 mb-4 rounded-lg shadow"
                      >
                        <div className="p-4">
                          <div className="flex justify-between items-center">
                            <h5
                              className="font-semibold"
                              style={{ marginRight: "10px" }}
                            >
                              Order #{order.orderId}
                            </h5>
                            <h5>
                              {format(
                                new Date(order.orderDate),
                                "dd-MM-yyyy hh:mm a"
                              )}
                            </h5>
                          </div>
                          <table className="w-full mt-4 text-left text-sm">
                            <thead>
                              <tr>
                                <th className="px-2 py-1">Image</th>
                                <th className="px-2 py-1">Item</th>
                                <th className="px-2 py-1">Category</th>
                                <th className="px-2 py-1">Item Code</th>
                                <th className="px-2 py-1">Price Details</th>
                                <th className="px-2 py-1">Qty</th>
                                <th className="px-2 py-1">Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {order.items.map((item, itemIndex) => (
                                <tr key={itemIndex}>
                                  <td className="px-2 py-1">
                                    <img
                                      src={item.imageUrl}
                                      alt={item.itemName}
                                      className="h-10 w-10 object-cover rounded-md"
                                    />
                                  </td>
                                  <td className="px-2 py-1">{item.itemName}</td>
                                  <td className="px-2 py-1">{item.category}</td>
                                  <td className="px-2 py-1">{item.itemCode}</td>
                                  <td className="px-2 py-1">
                                    {item.priceDetails.priceType}
                                  </td>
                                  <td className="px-2 py-1">{item.quantity}</td>
                                  <td className="px-2 py-1">
                                    <b>{item.priceDetails.price}</b>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="bg-gray-200 p-4 text-right rounded-b-lg">
                          <b>Total : {order.totalPrice}</b>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            <div className="w-1/3">{/* Additional content for col-4 */}</div>
          </Box>

          <div className="card-footer text-right text-xl flex items-center justify-center">
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopOrders;
