import React from "react";
import { useEffect, useState } from 'react';
import {
  FaFileInvoiceDollar,
  FaClipboardList,
  FaBoxes,
  FaUsers,
  FaQrcode,
  FaFileInvoice,
  FaChartBar,
  FaUserCog,
} from "react-icons/fa";
import { MdExpandMore } from "react-icons/md";
// import printingInvoicesImage from './assets/undraw_printing_invoices_5r4r.png'
import printingInvoicesImage from "../assets/undraw_printing_invoices_5r4r.png";
import MainPageImage from "../assets/mainImage2.png";
import download from "../assets/mainImage.png";

// D:\Bill Karlo Projects\billkarlo-web\src\assets\undraw_data_re_80ws.svg
// D:\Bill Karlo Projects\billkarlo-web\src\assets\undraw_analytics_re_dkf8.svg
import InvestingImage from "../assets/undraw_Investing_re_bov7.png";

import Analytics from "../assets/undraw_predictive_analytics_re_wxt8.svg";
import Savings from "../assets/undraw_savings_re_eq4w.svg";
import Wallet from "../assets/undraw_payments_re_77x0.svg";
import cash from "../assets/undraw_projections_re_ulc6.svg";
import business from "../assets/undraw_informed_decision_p2lh.svg"

import billingProcess from "../assets/billingProcessSS.jpeg"
import homeScreenNext from "../assets/HomeScreenSS.jpeg"
import reportsIMage from "../assets/reportsIMage.png"
import tableManagment from "../assets/TableManagmentSS.jpeg"



import CEOIMAGE from "../assets/parameshImage.jpg";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { RiTwitterXFill } from "react-icons/ri";
import { FaYoutube } from "react-icons/fa";

const Dashboard = () => {
  useEffect(() => {
    const section = document.getElementById("table-management-section");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            section.classList.add("opacity-100");
            section.classList.remove("translate-y-10");
            section.classList.add("translate-y-0");
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    observer.observe(section);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const section = document.getElementById("homeScreen-section");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            section.classList.add("opacity-100");
            section.classList.remove("translate-y-10");
            section.classList.add("translate-y-0");
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    observer.observe(section);

    return () => observer.disconnect();
  }, []);
  useEffect(() => {
    const section = document.getElementById("billing-section");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            section.classList.add("opacity-100");
            section.classList.remove("translate-y-10");
            section.classList.add("translate-y-0");
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    observer.observe(section);

    return () => observer.disconnect();
  }, []);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const elementPosition = document.getElementById('main-content').offsetTop;
      if (scrollPosition > elementPosition) {
        setInView(true);
      } else {
        setInView(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check on mount

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  const [View, InView] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById('necessarySection');
      if (section) {
        const scrollPosition = window.scrollY + window.innerHeight;
        const sectionPosition = section.offsetTop;

        if (scrollPosition > sectionPosition) {
          InView(true);
        } else {
          InView(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check on mount

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <div>
      <div
        className="leading-normal tracking-normal text-white gradient"
        style={{
          fontFamily: "Source Sans Pro, sans-serif",
          // background: "linear-gradient(90deg, #694DA5 0%, #41257A 100%)",
        }}
      >
        <nav
          id="header"
          className="fixed w-full top-0 text-white z-[999] shadow-md rounded bg-white"
        >
          <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2 px-4">
            <div
              className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-2 lg:p-0 z-20"
              id="nav-content"
            >
              <div className="items-start">
                <Link to="/">
                  <img
                    className="w-[64px] h-[64px] mr-2 cursor-pointer"
                    src="https://firebasestorage.googleapis.com/v0/b/token-fefde.appspot.com/o/profilePic%2F15W6OxzhvYgITBgc2wRGZL3cNBG3?alt=media&token=0639d1d3-6954-4680-88d5-d3b9c76e0137"
                    alt="logo"
                  />
                </Link>
              </div>
              <ul className="list-reset lg:flex justify-end flex-1 items-center pointer-events-none">
                <li className="mr-2">
                  <a
                    className="inline-block py-1 px-2 text-black font-bold no-underline"
                    href="#"
                  ></a>
                </li>
                <li className="mr-2">
                  <a
                    className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-1 px-2"
                    href="#"
                  ></a>
                </li>
                <li className="mr-2">
                  <a
                    className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-1 px-2"
                    href="#"
                  ></a>
                </li>
              </ul>
              <Link to="/login">
                <button className="mx-auto lg:mx-0 hover:underline bg-purple-900  text-white font-semibold rounded-md py-2 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                  Get Started
                </button>
              </Link>
            </div>
          </div>
          <hr className=" border-gray-100 opacity-25 my-0 py-0" />
        </nav>

        <div className="pt-24 mb-10">
          <div
            id="main-content"
            className={`flex flex-col md:flex-row w-full md:w7/12 items-center md:items-start text-center md:text-left ${inView ? 'scale-up' : 'scale-up-hidden'
              }`}
          >
            <img
              className={`w-full md:w-[1000px] md:h-[600px] object-cover mb-6 md:mb-0 ${inView ? 'scale-up' : 'scale-up-hidden'
                }`}
              src={MainPageImage}
              alt="Main Page"
            />
            <div
              className={`flex flex-col justify-center items-center md:items-start text-center mt-16 md:text-left ${inView ? 'fade-in' : 'fade-in-hidden'}`}
            >
              <h1 className="text-4xl font-bold mb-4 text-black mt-10">
                Looking for Affordable Billing Solutions? We’re Here for You!
              </h1>
              <p className="text-xl mb-6 text-black mt-3">
                Introducing Bill Karlo Billing Software – the easiest way to manage your invoices and payments.
              </p>
              <Link to="/login">
                <button className="mx-auto lg:mx-0 hover:underline bg-purple-900 text-white font-bold rounded-full mt-16 my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline">
                  Log in Now
                </button>
              </Link>
            </div>
          </div>
        </div>

        <section className="bg-white py-8">
          <div className="container max-w-5xl mx-auto m-8">
            <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-purple-900">
              Bill Karlo
            </h1>
            <div className="w-full mb-4">
              <div
                className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"
                style={{
                  fontFamily: "Source Sans Pro, sans-serif",
                  background: "linear-gradient(90deg, #41257A, #41257A 100%)",
                }}
              ></div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-5/6 sm:w-1/2 p-6">
                <h3 className="text-3xl text-purple-900 font-bold leading-none mb-3">
                  Why Bill Karlo ?
                </h3>
                <p className="text-gray-600 mb-8">
                  BillKarlo is a comprehensive software solution designed
                  specifically for small businesses to streamline their
                  operations. This Android application offers a range of
                  features such as billing and payments, order management,
                  inventory tracking, customer relationship management (CRM),
                  reporting and analytics, QR code ordering, and admin portals
                  for franchise management. This document provides an in-depth
                  analysis of the BillKarlo software, covering its features,
                  applications, benefits, challenges, and future prospects.
                  <br />
                  <br />
                </p>
              </div>
              <div className="w-full sm:w-1/2 p-6">
                <img
                  src={printingInvoicesImage}
                  alt="Description of image 1"
                  className="w-full h-auto rounded-lg"
                />{" "}
                <title>travel booking</title>
              </div>
            </div>
            <div className="flex flex-wrap flex-col-reverse sm:flex-row">
              <div className="w-full sm:w-1/2 p-6 mt-6">
                <img
                  src={InvestingImage}
                  alt="Description of image 1"
                  className="w-full h-auto rounded-lg"
                />{" "}
                <title>travel booking</title>
              </div>
              <div className="w-full sm:w-1/2 p-6 mt-6">
                <div className="align-middle">
                  <h3 className="text-3xl text-purple-900 font-bold leading-none mb-3">
                    What Problem Does Your Software Solve?
                  </h3>
                  <p className="text-gray-600 mb-8">
                    Our software addresses several critical pain points for
                    small and medium-sized businesses, particularly in the realm
                    of financial management and operational efficiency.
                    Traditional methods of managing business finances,
                    inventory, customer relationships, and orders are often
                    cumbersome, error-prone, and time-consuming. Our software
                    automates these processes, providing a seamless experience
                    that saves time and reduces errors.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <div class="flex">
          <div class="w-5/12">

          </div>
          <div class="w-7/12">
            <img
              // style={{ mixBlendMode: "multiply" }}
              className="w-full h-128 w-100"
              src={MainPageImage}
            />
          </div>

        </div> */}
        <section className="bg-purple-50 py-8">

          <div
            id="necessarySection"
            className={`container  mx-auto px-4 py-8 ${View ? 'fade-up-visible' : 'fade-up-hidden'}`}
          >
            <h1 className="text-4xl font-bold text-center mb-8 text-purple-900">
              Why Billing is Necessary for Small Business Owners
            </h1>
            <div className="flex space-x-8 flex-container-scroll">
              <div className={`flex-shrink-0 w-80 bg-white p-6 rounded-lg shadow-lg ${View ? 'scale-slide-visible' : 'scale-slide-hidden'}`}>
                <img src={Wallet} alt="Cash Flow Management" className="w-full h-40 object-contain rounded-t-lg" />
                <h2 className="text-xl font-bold mt-4">Cash Flow Management</h2>
                <p className="text-gray-700 mt-2">
                  Proper billing ensures timely payments from customers, which is essential for maintaining a healthy cash flow.
                </p>
              </div>
              <div className={`flex-shrink-0 w-80 bg-white p-6 rounded-lg shadow-lg ${View ? 'scale-slide-visible' : 'scale-slide-hidden'}`}>
                <img src={Analytics} alt="Financial Tracking and Analysis" className="w-full h-40 object-contain rounded-t-lg" />
                <h2 className="text-xl font-bold mt-4">Financial Tracking and Analysis</h2>
                <p className="text-gray-700 mt-2">
                  Effective billing systems provide valuable data for financial tracking and analysis.
                </p>
              </div>
              <div className={`flex-shrink-0 w-80 bg-white p-6 rounded-lg shadow-lg ${View ? 'scale-slide-visible' : 'scale-slide-hidden'}`}>
                <img src={Savings} alt="Efficiency and Time Savings" className="w-full h-40 object-contain rounded-t-lg" />
                <h2 className="text-xl font-bold mt-4">Efficiency and Time Savings</h2>
                <p className="text-gray-700 mt-2">
                  Automated billing software streamlines the invoicing process, reducing the time and effort required.
                </p>
              </div>
              <div className={`flex-shrink-0 w-80 bg-white p-6 rounded-lg shadow-lg ${View ? 'scale-slide-visible' : 'scale-slide-hidden'}`}>
                <img src={cash} alt="Record Keeping" className="w-full h-40 object-contain rounded-t-lg" />
                <h2 className="text-xl font-bold mt-4">Record Keeping</h2>
                <p className="text-gray-700 mt-2">
                  Proper billing practices contribute to organized and thorough record-keeping.
                </p>
              </div>
              <div className={`flex-shrink-0 w-80 bg-white p-6 rounded-lg shadow-lg ${View ? 'scale-slide-visible' : 'scale-slide-hidden'}`}>
                <img src={business} alt="Enhanced Business Image" className="w-full h-40 object-contain rounded-t-lg" />
                <h2 className="text-xl font-bold mt-4">Enhanced Business Image</h2>
                <p className="text-gray-700 mt-2">
                  Consistent and professional billing enhances the overall image of the business.
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="relative -mt-12 lg:-mt-24">
          <svg
            viewBox="0 0 1428 174"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g
                transform="translate(-2.000000, 44.000000)"
                fill="#FFFFFF"
                fill-rule="nonzero"
              >
                <path
                  d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                  opacity="0.100000001"
                ></path>
                <path
                  d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                  opacity="0.100000001"
                ></path>
                <path
                  d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                  id="Path-4"
                  opacity="0.200000003"
                ></path>
              </g>
              <g
                transform="translate(-4.000000, 76.000000)"
                fill="#FFFFFF"
                fill-rule="nonzero"
              >
                <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
              </g>
            </g>
          </svg>
        </div>
        <section className="bg-white">
          <h1 className="text-4xl font-bold text-center mb-8 text-purple-900">
            Discover How Effortless Managing Your Billing Can Be with Bill Karlo
          </h1>
          <div className="container max-w-5xl mx-auto">

            <div className="flex flex-wrap items-center justify-center opacity-0 transition-opacity duration-1000 transform translate-y-10" id="homeScreen-section">
              <div className="w-full sm:w-1/2 p-6 flex justify-center sm:justify-end">
                <img
                  src={homeScreenNext}
                  alt="Description of image 1"
                  className="w-full h-auto rounded-lg max-w-xs sm:max-w-md"
                />
              </div>
              <div className="w-full sm:w-1/2 p-6">
                <h3 className="text-3xl text-purple-800 font-bold leading-none mb-3 text-center sm:text-left">
                  Home Screen
                </h3>
                <p className="text-gray-600 mb-8 text-center sm:text-left">
                  You can easily navigate the home screen of our application. Use the item code feature to quickly filter and find specific items. Adding or removing items from your list is simple and intuitive. Once you’ve organized your items, click 'Next' to proceed to the billing screen, where you can complete transactions swiftly and efficiently. This streamlined process ensures a smooth experience for managing your inventory and handling payments.
                  <br />
                  <Link to="/login">
                    <button className="m-3 mx-auto lg:mx-0 hover:underline bg-purple-900  text-white font-semibold rounded-md py-2 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                      Sign Up
                    </button>
                  </Link>

                  <br />
                </p>
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-center opacity-0 transition-opacity duration-1000 transform translate-y-10" id="table-management-section">
              <div className="w-full sm:w-1/2 p-6 mt-6">
                <div className="align-middle">
                  <h3 className="text-3xl text-purple-800 font-bold leading-none mb-3">
                    Table Management
                  </h3>
                  <p className="text-gray-600 mb-8">
                    Effortlessly manage your tables with our application. Easily add or delete tables as needed, assign items to specific tables, and view the status of all tables in real-time. This intuitive system simplifies table management, ensuring a smooth and efficient operation.
                    <br />
                    <Link to="/login">
                      <button className="m-3 mx-auto lg:mx-0 hover:underline bg-purple-900  text-white font-semibold rounded-md py-2 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                        Sign Up
                      </button>
                    </Link>

                    <br />
                  </p>
                </div>

              </div>
              <div className="w-full sm:w-1/2 p-6 mt-6">
                <img
                  src={tableManagment}
                  alt="Description of image 1"
                  className="w-full h-auto rounded-lg"
                />{" "}
              </div>
            </div>


            <div className="flex flex-wrap items-center justify-center opacity-0 transition-opacity duration-1000 transform translate-y-10" id="billing-section">
              <div className="w-full sm:w-1/2 p-6 flex justify-center sm:justify-end">
                <img
                  src={billingProcess}
                  alt="Description of image 1"
                  className="w-full h-auto rounded-lg max-w-xs sm:max-w-md"
                />
              </div>
              <div className="w-full sm:w-1/2 p-6">
                <h3 className="text-3xl text-purple-800 font-bold leading-none mb-3 text-center sm:text-left">
                  Billing Process
                </h3>
                <p className="text-gray-600 mb-8 text-center sm:text-left">
                  Streamline your billing process with ease using our intuitive system. Effortlessly create seamless bills by adding or removing items and applying discounts as needed. Choose the cash payment mode for quick transactions. You can also clear the entire cart or adjust item quantities with a simple click. This comprehensive billing solution ensures a smooth and efficient checkout experience, making it easier than ever to manage transactions and provide excellent customer service.
                  <br />
                  <Link to="/login">
                    <button className="m-3 mx-auto lg:mx-0 hover:underline bg-purple-900  text-white font-semibold rounded-md py-2 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                      Sign Up
                    </button>
                  </Link>

                  <br />
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white py-4">
          <div className="container mx-auto flex flex-wrap pt-4 pb-12">
            <div className="text-center">
              <b className="block text-3xl text-purple-900">Bill Karlo</b>
              <h5 className="w-full my-2 text-2xl leading-tight text-center text-gray-800">
                Start using our billing software, equipped with best-in-class smart features designed to help your business grow and succeed.
              </h5>
            </div>

            <div className="w-full mb-4">
              <div
                className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"
                style={{
                  fontFamily: "Source Sans Pro, sans-serif",
                  background: "linear-gradient(90deg, #694DA50%, #41257A 100%)",
                }}
              ></div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/4 p-2 flex flex-col flex-grow">
                <div className="flex-1 m-3">
                  <div className="flex flex-col items-center  py-[20px]">
                    <FaFileInvoiceDollar className="text-purple-900 text-4xl mb-5" />
                    <div className="text-xl text-gray-800 mb-3 font-semi-bold text-center">
                      Billing and Payments
                    </div>
                    <p className="text-gray-800 text-base px-4 text-center">
                      BillKarlo simplifies the billing process by allowing
                      businesses to create and send bills electronically.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/4 p-2 flex flex-col flex-grow">
                <div className="flex-1 m-3">
                  <div className="flex flex-col items-center  py-[20px]">
                    <FaQrcode className="text-purple-900 text-4xl mb-5" />
                    <div className="text-xl text-gray-800 mb-3 ml-0 font-semi-bold text-center">
                      QR Code Ordering
                    </div>
                    <p className="text-gray-800 text-base text-center">
                      This innovative feature enables customers to place orders
                      by scanning QR codes, simplifying the ordering process
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/4 p-2 flex flex-col flex-grow">
                <div className="flex-1 m-3">
                  <div className="flex flex-col items-center  py-[20px]">
                    <FaBoxes className="text-purple-900 text-4xl mb-5" />
                    <div className="text-xl text-gray-800 mb-3 font-semi-bold text-center">
                      Inventory Tracking
                    </div>
                    <p className="text-gray-800 text-base text-center">
                      BillKarlo provides tools to monitor inventory levels,
                      alerting businesses when stocks are low to prevent
                      shortages.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/4 p-2 flex flex-col flex-grow">
                <div className="flex-1 m-3">
                  <div className="flex flex-col items-center  py-[20px]">
                    <FaFileInvoice className="text-purple-900 text-4xl mb-5" />
                    <div className="text-xl text-gray-800 mb-3 font-semi-bold text-center">
                      GST Compliant Billing
                    </div>
                    <p className="text-gray-800 text-base text-center">
                      BillKarlo ensures compliance with India’s GST regulations
                      by generating GST-compliant bills, simplifying tax
                      processes for businesses
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/4 p-2 flex flex-col flex-grow">
                <div className="flex-1 m-3">
                  <div className="flex flex-col items-center  py-[20px]">
                    <FaChartBar className="text-purple-900 text-4xl mb-5" />
                    <div className="text-xl text-gray-800 mb-3 font-semi-bold text-center">
                      Reporting and <br />Analytics:
                    </div>
                    <p className="text-gray-800 text-base px-4 text-center">
                      BillKarlo offers robust reporting tools to analyze sales
                      data, track business performance, and identify trends.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/4 p-2 flex flex-col flex-grow">
                <div className="flex-1 m-3">
                  <div className="flex flex-col items-center py-[20px]">
                    <FaClipboardList className="text-purple-900 text-4xl mb-5" />
                    <div className="text-xl text-gray-800 mb-3 font-semi-bold text-center">
                      Order Taking and Management:
                    </div>
                    <p className="text-gray-800 text-base text-center">
                      The software helps businesses efficiently manage orders by
                      keeping track of pending, ongoing, and completed orders.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/4 p-2 flex flex-col flex-grow">
                <div className="flex-1 m-3">
                  <div className="flex flex-col items-center  py-[20px]">
                    <FaUserCog className="text-purple-900 text-4xl mb-5" />
                    <div className="text-xl text-gray-800 mb-3 font-semi-bold text-center">
                      Admin Portals & Franchise Management:
                    </div>
                    <p className="text-gray-800 text-base text-center">
                      The admin portal allows for centralized management of
                      multiple business locations. Franchise management features
                      help streamline operations across different branches,
                      ensuring consistency and efficiency.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/4 p-2 flex flex-col flex-grow">
                <div className="flex-1 m-3">
                  <div className="flex flex-col items-center py-[20px]">
                    <FaUsers className="text-purple-900 text-4xl mb-5" />
                    <div className="text-xl text-gray-800 mb-3 font-semi-bold text-center">
                      Customer Relationship Management:
                    </div>
                    <p className="text-gray-800 text-base text-center">
                      The CRM feature allows businesses to store and manage
                      customer information, preferences, and purchase history.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="bg-white flex justify-center">
          <section className="w-9/12 m-5 py-16 border bg-purple-100">
            <div>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">


                <div className="flex flex-col items-center lg:flex-row">
                  <div className="w-5/12 flex justify-center">
                    <img
                      className="w-[250px] h-[250px] mr-2 cursor-pointer"
                      src="https://firebasestorage.googleapis.com/v0/b/token-fefde.appspot.com/o/profilePic%2F15W6OxzhvYgITBgc2wRGZL3cNBG3?alt=media&token=0639d1d3-6954-4680-88d5-d3b9c76e0137"
                      alt="logo"
                    />
                    {/* <img
                      className=" h-60 w-48 object-cover"
                      src={CEOIMAGE} // Update this with the correct path
                      alt="Paramesh Mamidi Shetti"
                    /> */}
                  </div>
                  <div className="w-8/12">
                    <h2 className="text-2xl font-bold text-gray-900">
                      How To Download Billing Software for Your Mobile
                    </h2>

                    <p className="mt-4 text-gray-700">
                      Access the best GST billing software for PC by clicking the button below to sign up. Our  software offers a robust suite of features designed to streamline your billing processes, making it easier to manage invoices, track payments, and generate detailed reports.
                    </p>
                    <p className="mt-4 text-gray-700">
                      For mobile users, access the best GST billing software by heading to the Play Store, searching for "Bill Karlo," and downloading the app. Our mobile application provides the convenience of on-the-go billing, allowing you to monitor your business performance right from your smartphone.
                    </p>
                    <p className="mt-4 text-gray-700">
                      Enjoy the benefits of a flexible and scalable billing solution that grows alongside your business.  Start your journey with a free trial of Bill Karlo billing software and experience its remarkable capabilities. Download now and embark on a seamless billing experience.
                    </p>


                    <Link to="/login">
                      <button className="m-3 mx-auto lg:mx-0 hover:underline bg-purple-900  text-white font-semibold rounded-md py-2 px-4 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                        Sign Up
                      </button>
                    </Link>

                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section
          id="pricing"
          className="our_pricing section-padding bg-gray-100 pt-5 pb-[40px]"
        >
          <div className="container mx-auto">
            <div className="text-center">
              <h1 className="text-3xl font-bold mb-4 text-black">
                Pricing Plans
              </h1>
            </div>
            <div className="flex flex-wrap -mx-4 mt-8">
              <div className="w-full md:w-1/3 px-4">
                <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                  <div className="px-6 py-4 bg-purple-900 text-white text-center">
                    <h3 className="text-xl font-bold mb-2">Basic</h3>
                    <h5 className="text-sm">Free Trial (1 Month)</h5>
                    <h1 className="text-4xl font-bold my-4">
                      {/* <span className="block text-lg line-through opacity-50">
                        ₹1194
                      </span> */}
                      <span className="block">₹0</span>
                    </h1>{" "}
                  </div>
                  <ul className="text-gray-700 text-sm px-6 py-4">
                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-green-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">Billing</p>
                      </div>
                      <small className="ml-8">
                        Unlimited access to comprehensive Billing features
                      </small>
                    </li>
                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-green-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">Reports</p>
                      </div>
                      <small className="ml-8">
                        Effortlessly manage reports for efficient operations.
                      </small>
                    </li>
                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-green-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">QR Code Ordering</p>
                      </div>
                      <small className="ml-8">
                        Seamlessly handle orders with QR Code Ordering feauture
                      </small>
                    </li>
                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-gray-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">Table Management</p>
                      </div>
                      <small className="ml-8">
                        Effortlessly create and manage tables for efficient
                        operations
                      </small>
                    </li>
                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-gray-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">Payments</p>
                      </div>
                      <small className="ml-8">
                        Seamlessly handle payments with unlimited support
                      </small>
                    </li>

                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-gray-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">Digital Menu</p>
                      </div>
                      <small className="ml-8">
                        Create and Customize digital menus for easy ordering
                      </small>
                    </li>
                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-gray-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">24/7 Support</p>
                      </div>
                      <small className="ml-8">
                        Get round-the-clock assistance whenever you need it
                      </small>
                    </li>
                  </ul>

                  <div className="text-center pb-6">
                    <a
                      href="#"
                      className="inline-block bg-purple-900 hover:bg-purple-400 text-white font-bold py-2 px-4 rounded-full mt-4 transition duration-300"
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/3 px-4">
                <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                  <div className="px-6 py-4 bg-purple-900 text-white text-center">
                    <h3 className="text-xl font-bold mb-2">Advanced</h3>
                    <h5 className="text-sm">₹249/Per Month</h5>
                    <h1 className="text-4xl font-bold my-4">
                      {/* <span className="block text-lg line-through opacity-50">
                        ₹2094
                      </span> */}
                      <span className="block">₹249</span>
                    </h1>{" "}
                  </div>
                  <ul className="text-gray-700 text-sm px-6 py-4">
                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-green-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">Billing</p>
                      </div>
                      <small className="ml-8">
                        Unlimited access to comprehensive Billing features
                      </small>
                    </li>
                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-green-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">Reports</p>
                      </div>
                      <small className="ml-8">
                        Effortlessly manage reports for efficient operations.
                      </small>
                    </li>
                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-green-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">QR Code Ordering</p>
                      </div>
                      <small className="ml-8">
                        Seamlessly handle orders with QR Code Ordering feauture
                      </small>
                    </li>

                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-gray-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">Table Management</p>
                      </div>
                      <small className="ml-8">
                        Effortlessly create and manage tables for efficient
                        operations
                      </small>
                    </li>
                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-gray-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">Payments</p>
                      </div>
                      <small className="ml-8">
                        Seamlessly handle payments with unlimited support
                      </small>
                    </li>

                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-gray-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">Digital Menu</p>
                      </div>
                      <small className="ml-8">
                        Create and Customize digital menus for easy ordering
                      </small>
                    </li>
                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-gray-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">24/7 Support</p>
                      </div>
                      <small className="ml-8">
                        Get round-the-clock assistance whenever you need it
                      </small>
                    </li>
                  </ul>

                  <div className="text-center pb-6">
                    <a
                      href="#"
                      className="inline-block bg-purple-900 hover:bg-purple-400 text-white font-bold py-2 px-4 rounded-full mt-4 transition duration-300"
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/3 px-4">
                <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                  <div className="px-6 py-4 bg-purple-900 text-white text-center">
                    <h3 className="text-xl font-bold mb-2">Pro</h3>
                    <h5 className="text-sm">₹499/Per Month</h5>
                    <h1 className="text-4xl font-bold my-4">
                      {/* <span className="block text-lg line-through opacity-50">
                        ₹499
                      </span> */}
                      <span className="block">₹499</span>
                    </h1>{" "}
                  </div>
                  <ul className="text-gray-700 text-sm px-6 py-4">
                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-green-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">Billing</p>
                      </div>
                      <small className="ml-8">
                        Unlimited access to comprehensive Billing features
                      </small>
                    </li>
                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-green-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">Reports</p>
                      </div>
                      <small className="ml-8">
                        Effortlessly manage reports for efficient operations.
                      </small>
                    </li>
                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-green-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">QR Code Ordering</p>
                      </div>
                      <small className="ml-8">
                        Seamlessly handle orders with QR Code Ordering feauture
                      </small>
                    </li>
                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-green-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">Table Management</p>
                      </div>
                      <small className="ml-8">
                        Effortlessly create and manage tables for efficient
                        operations
                      </small>
                    </li>
                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-green-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">Payments</p>
                      </div>
                      <small className="ml-8">
                        Seamlessly handle payments with unlimited support
                      </small>
                    </li>

                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-green-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">Digital Menu</p>
                      </div>
                      <small className="ml-8">
                        Create and Customize digital menus for easy ordering
                      </small>
                    </li>
                    <li className="mb-2">
                      <div className="flex items-center">
                        <div className="bg-green-300 rounded-md p-1">
                          <svg
                            className="w-4 h-4 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                        <p className="m-2">24/7 Support</p>
                      </div>
                      <small className="ml-8">
                        Get round-the-clock assistance whenever you need it
                      </small>
                    </li>
                  </ul>

                  <div className="text-center pb-6">
                    <a
                      href="#"
                      className="inline-block bg-purple-900 hover:bg-purple-400 text-white font-bold py-2 px-4 rounded-full mt-4 transition duration-300"
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white py-16">
          <div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="lg:text-center">
                <h2 className="text-base text-purple-700 font-semibold tracking-wide uppercase">
                  Our Founder
                </h2>
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Meet Paramesh Mamidisetti
                </p>
              </div>

              <div className="mt-10">
                <div className="flex flex-col items-center lg:flex-row">
                  <div className="w-5/12 flex justify-center">
                    <img
                      className="h-80 w-80 object-cover rounded-md shadow-lg"
                      src={CEOIMAGE} // Update this with the correct path
                      alt="Paramesh Mamidi Shetti"
                    />
                  </div>
                  <div className="mt-8 w-7/12">
                    <h3 className="text-2xl font-bold text-gray-900">
                      Paramesh Mamidisetti
                    </h3>
                    <p className="text-xl font-semibold text-purple-700">
                      Co-founder & CEO of BillKarlo
                    </p>
                    <p className="mt-4 text-gray-700">
                      Parameshmamidisetti is the visionary Co-founder and CEO of
                      BillKarlo. He initiated the development of BillKarlo in
                      June 2023, driven by his passion for innovation and
                      technology. With three years of extensive experience in
                      Flutter development, Paramesh has honed his skills as a
                      self-taught developer.
                    </p>
                    <p className="mt-4 text-gray-700">
                      Before founding BillKarlo, he worked at a tech company
                      where he specialized in creating dynamic, cross-platform
                      mobile applications. Paramesh's expertise in Flutter,
                      combined with his commitment to excellence, has been
                      instrumental in shaping the vision and success of
                      BillKarlo.
                    </p>
                    <p className="mt-4 text-gray-700">
                      His journey from a self-learner to a leader in the tech
                      industry exemplifies his dedication and passion for
                      technology. Paramesh continues to leverage his knowledge
                      to develop cutting-edge solutions for businesses, driving
                      innovation at every step.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white dark:bg-gray-900">
          <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
              Contact Us
            </h2>
            <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
              Got a technical issue? Want to send feedback about a feature? Need
              details about our Business plan? Let us know.
            </p>
            <form action="#" className="space-y-8">
              <div>
                <label
                  for="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Your email
                </label>
                <input
                  type="email"
                  id="email"
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder="example@gmail.com"
                  required
                />
              </div>
              <div>
                <label
                  for="subject"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder="Let us know how we can help you"
                  required
                />
              </div>
              <div className="sm:col-span-2">
                <label
                  for="message"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                >
                  Your message
                </label>
                <textarea
                  id="message"
                  rows="6"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Leave a comment..."
                ></textarea>
              </div>
              <button
                type="submit"
                className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-[#674FA3] sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-800"
              >
                Send message
              </button>
            </form>
          </div>
        </section>
      </div>
      <section className="max-w-5xl mx-auto p-8 bg-white">
        <h2 className="text-3xl font-bold text-center mb-12 text-black">
          Frequently Asked Questions
        </h2>
        <Accordion>
          <AccordionSummary
            expandIcon={<MdExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <h4 className="text-xl font-medium mb-2">
              What Problem Does Your Software Solve?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className="text-gray-700">
              Our software addresses several critical pain points for small and
              medium-sized businesses, particularly in the realm of financial
              management and operational efficiency. Traditional methods of
              managing business finances, inventory, customer relationships, and
              orders are often cumbersome, error-prone, and time-consuming. Our
              software automates these processes, providing a seamless
              experience that saves time and reduces errors.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion className="mt-5 rounded-md">
          <AccordionSummary
            expandIcon={<MdExpandMore />}
            aria-controls="panel1-content"
            id="panel2-header"
          >
            <h4 className="text-xl font-medium mb-2">
              Who Is Your Target Market?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className="text-gray-700">
              Our primary target market comprises small to medium-sized
              businesses that require cost-effective, yet comprehensive software
              solutions to manage their operations. These businesses often face
              budget constraints that limit their ability to invest in high-end,
              expensive software systems.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion className="mt-5 rounded-md">
          <AccordionSummary
            expandIcon={<MdExpandMore />}
            aria-controls="panel1-content"
            id="panel2-header"
          >
            <h4 className="text-xl font-medium mb-2">
              What Is Your Unique Selling Proposition (USP)?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className="text-gray-700">
              Our unique selling proposition is the comprehensive suite of
              features we offer, which caters to all aspects of business
              operations. These features include:
            </p>
            <ul className="list-disc list-inside text-gray-700">
              <li>Inventory Tracking</li>
              <li>Customer Relationship Management (CRM)</li>
              <li>Reporting and Analytics</li>
              <li>QR Code Ordering</li>
              <li>Admin Portals and Franchise Management</li>
              <li>Generating GST Compliant Bills</li>
              <li>Table Management</li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion className="mt-5 rounded-md">
          <AccordionSummary
            expandIcon={<MdExpandMore />}
            aria-controls="panel1-content"
            id="panel2-header"
          >
            <h4 className="text-xl font-medium mb-2">
              What Is Your Business Model?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className="text-gray-700">
              Our business model is subscription-based, designed to lower the
              barrier to entry for small and medium-sized businesses while
              ensuring sustained revenue.
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-4">
              <li>
                <span className="text-lg font-medium">Free Trial Period</span>:
                <span className="block ml-6">
                  Businesses can use the software for free for the first three
                  months, allowing them to experience its full capabilities
                  without any initial cost. During this period, the only cost is
                  for the printer, if needed.
                </span>
              </li>
              <li>
                <span className="text-lg font-medium">Subscription Plans</span>:
                <span className="block ml-6">
                  After the free trial, businesses can continue using the
                  software by subscribing to one of our affordable plans. This
                  model ensures ongoing revenue while making the software
                  accessible to businesses of all sizes.
                </span>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion>
          {" "}
          <AccordionSummary
            expandIcon={<MdExpandMore />}
            aria-controls="panel1-content"
            id="panel3-header"
          >
            <h4 className="text-xl font-medium mb-2">
              What Is Your Unique Selling Proposition (USP)?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className="text-gray-700">
              Our unique selling proposition is the comprehensive suite of
              features we offer, which caters to all aspects of business
              operations. These features include:
            </p>
            <ul className="list-disc list-inside text-gray-700">
              <li>Inventory Tracking</li>
              <li>Customer Relationship Management (CRM)</li>
              <li>Reporting and Analytics</li>
              <li>QR Code Ordering</li>
              <li>Admin Portals and Franchise Management</li>
              <li>Generating GST Compliant Bills</li>
              <li>Table Management</li>
            </ul>
          </AccordionDetails>
        </Accordion> */}
        {/* <Accordion>
          {" "}
          <AccordionSummary
            expandIcon={<MdExpandMore />}
            aria-controls="panel1-content"
            id="panel4-header"
          >
            <h4 className="text-xl font-medium mb-2">
              What Is Your Business Model?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className="text-gray-700">
              Our business model is subscription-based, designed to lower the
              barrier to entry for small and medium-sized businesses while
              ensuring sustained revenue.
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-4">
              <li>
                <span className="text-lg font-medium">Free Trial Period</span>:
                <span className="block ml-6">
                  Businesses can use the software for free for the first three
                  months, allowing them to experience its full capabilities
                  without any initial cost. During this period, the only cost is
                  for the printer, if needed.
                </span>
              </li>
              <li>
                <span className="text-lg font-medium">Subscription Plans</span>:
                <span className="block ml-6">
                  After the free trial, businesses can continue using the
                  software by subscribing to one of our affordable plans. This
                  model ensures ongoing revenue while making the software
                  accessible to businesses of all sizes.
                </span>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion> */}
        <Accordion className="mt-5 rounded-md">
          <AccordionSummary
            expandIcon={<MdExpandMore />}
            aria-controls="panel1-content"
            id="panel5-header"
          >
            <h4 className="text-xl font-medium mb-2">
              How Will You Handle Data Security and Privacy?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className="text-gray-700">
              Data security and privacy are paramount in our software
              development process
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-4">
              <li>
                <span className="text-lg font-medium">
                  Encryption Protocols:
                </span>
                <span className="block ml-6">
                  Implementing robust encryption to protect data during
                  transmission and storage.
                </span>
              </li>
              <li>
                <span className="text-lg font-medium">Access Controls</span>:
                <span className="block ml-6">
                  Strict access controls to ensure only authorized personnel can
                  access sensitive information.
                </span>
              </li>
              <li>
                <span className="text-lg font-medium">Regular Audits</span>:
                <span className="block ml-6">
                  Conducting regular security audits to identify and mitigate
                  potential vulnerabilities.
                </span>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
        {/* <div className="mb-8">
          <h3 className="text-2xl font-semibold mb-4"></h3>

          <div className="bg-white p-6 rounded-lg shadow-md mb-4">
            <h4 className="text-xl font-medium mb-2">
              What Problem Does Your Software Solve?
            </h4>
            <p className="text-gray-700">
              Our software addresses several critical pain points for small and
              medium-sized businesses, particularly in the realm of financial
              management and operational efficiency. Traditional methods of
              managing business finances, inventory, customer relationships, and
              orders are often cumbersome, error-prone, and time-consuming. Our
              software automates these processes, providing a seamless
              experience that saves time and reduces errors.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md mb-4">
            <h4 className="text-xl font-medium mb-2">Who Is Your Target Market?</h4>
            <p className="text-gray-700">
              Our primary target market comprises small to medium-sized
              businesses that require cost-effective, yet comprehensive software
              solutions to manage their operations. These businesses often face
              budget constraints that limit their ability to invest in high-end,
              expensive software systems.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md mb-4">
            <h4 className="text-xl font-medium mb-2">
              What Is Your Unique Selling Proposition (USP)?
            </h4>
            <p className="text-gray-700">
              Our unique selling proposition is the comprehensive suite of
              features we offer, which caters to all aspects of business
              operations. These features include:
            </p>
            <ul className="list-disc list-inside text-gray-700">
              <li>Inventory Tracking</li>
              <li>Customer Relationship Management (CRM)</li>
              <li>Reporting and Analytics</li>
              <li>QR Code Ordering</li>
              <li>Admin Portals and Franchise Management</li>
              <li>Generating GST Compliant Bills</li>
              <li>Table Management</li>
            </ul>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md mb-4">
            <h4 className="text-xl font-medium mb-2">
              What Is Your Business Model?
            </h4>
            <p className="text-gray-700">
              Our business model is subscription-based, designed to lower the
              barrier to entry for small and medium-sized businesses while
              ensuring sustained revenue.
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-4">
              <li>
                <span className="text-lg font-medium">Free Trial Period</span>:
                <span className="block ml-6">
                  Businesses can use the software for free for the first three
                  months, allowing them to experience its full capabilities
                  without any initial cost. During this period, the only cost is
                  for the printer, if needed.
                </span>
              </li>
              <li>
                <span className="text-lg font-medium">Subscription Plans</span>:
                <span className="block ml-6">
                  After the free trial, businesses can continue using the
                  software by subscribing to one of our affordable plans. This
                  model ensures ongoing revenue while making the software
                  accessible to businesses of all sizes.
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div>
          <div className="bg-white p-6 rounded-lg shadow-md mb-4">
            <h4 className="text-xl font-medium mb-2">
              How Will You Handle Data Security and Privacy?
            </h4>
            <p className="text-gray-700">
              Data security and privacy are paramount in our software
              development process
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-4">
              <li>
                <span className="text-lg font-medium">Encryption Protocols:</span>
                <span className="block ml-6">
                  Implementing robust encryption to protect data during
                  transmission and storage.
                </span>
              </li>
              <li>
                <span className="text-lg font-medium">Access Controls</span>:
                <span className="block ml-6">
                  Strict access controls to ensure only authorized personnel can
                  access sensitive information.
                </span>
              </li>
              <li>
                <span className="text-lg font-medium">Regular Audits</span>:
                <span className="block ml-6">
                  Conducting regular security audits to identify and mitigate
                  potential vulnerabilities.
                </span>
              </li>
            </ul>
          </div>
        </div> */}
      </section>
      <footer className="bg-purple-900 text-gray-100 py-8">
        <div className="container mx-auto px-4">
          <div className="flex  justify-between">
            <div className="w-full md:w-1/4 mb-6 md:mb-0">
              <h5 className="uppercase font-bold mb-2">Company</h5>
              <ul>
                {/* <li className="mb-2">
                  <p className="hover:underline">About Us</p>
                </li>
                <li className="mb-2">
                  <p className="hover:underline">Careers</p>
                </li> */}
                <li className="mb-2">
                  <p>Bill Karlo</p>
                  <p>2-2-1107/89,Bayammagalli</p>
                  <p>Prasanthi Nagar,Tilak Nagar</p>

                  <p>500044,Hyderabad</p>
                  <p>India</p>
                </li>
              </ul>
            </div>
            <div className="w-full md:w-1/4 mb-6 md:mb-0">
              <h5 className="uppercase font-bold mb-2">Contact Us</h5>
              <ul>
                <li className="mb-2">
                  <p className="hover:underline">+91 9381403246</p>
                </li>
              </ul>
            </div>

            <div className="w-full md:w-1/4 mb-6 md:mb-0">
              <h5 className="uppercase font-bold mb-2">Links</h5>
              <ul>
                <Link to="/privacy">
                  <li className="mb-2">
                    <p className="hover:underline">Privacy Policy</p>
                  </li>
                </Link>
                <Link to="/termsandconditions">
                  <li className="mb-2">
                    <p className="hover:underline">Terms of Service</p>
                  </li>
                </Link>
                <Link to="/shipping">
                  <li className="mb-2">
                    <p className="hover:underline">Shipping</p>
                  </li>
                </Link>
                <Link to="/refund-policy">
                  <li className="mb-2">
                    <p className="hover:underline">Cancellation & Returns</p>
                  </li>
                </Link>
              </ul>
            </div>
            <div className="w-full md:w-1/4 mb-6 md:mb-0">
              <h5 className="uppercase font-bold mb-2">Follow Us</h5>
              <ul className="flex space-x-4">
                <li>
                  <FaInstagram size={20} />
                </li>
                <li>
                  <BsFacebook size={20} />
                </li>
                <li>
                  <RiTwitterXFill size={20} />
                </li>
                <li>
                  <FaYoutube size={20} />
                </li>
              </ul>
            </div>

            <div className="w-full md:w-1/4">
              <h5 className="uppercase font-bold mb-2">Newsletter</h5>
              <p className="mb-4">
                Subscribe to our newsletter for the latest updates
              </p>
              <form action="#">
                <input
                  type="email"
                  placeholder="Your email"
                  className="w-full px-3 py-2 text-gray-800 rounded-md mb-2"
                />
                <button className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded-md">
                  Subscribe
                </button>
              </form>
            </div>
          </div>

          <div className="text-center pt-6 mt-6 border-t border-gray-700 ">
            {/* <p className="text-sm">
              &copy; 2024 Company Name. All rights reserved.
            </p> */}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Dashboard;
