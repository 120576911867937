import { BsFillShieldLockFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "react-otp-input";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../firebase";
import {
  RecaptchaVerifier,
  getAuth,
  onAuthStateChanged,
  signInWithPhoneNumber,
} from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import logo from "../assets/bill karlo.jpg";
import loginimage from "../assets/undraw_secure_login_pdn4.png";
import { useNavigate } from "react-router-dom";
const LoginPage = () => {
  const userId = getAuth().currentUser;
  const navigation = useNavigate();
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState("");
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigation("/home"); // Redirect to home if the user is already logged in
      }
    });

    return () => unsubscribe();
  }, [auth, navigation]);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => { },
        },
        auth
      );
    }
  }

  function onSignup(e) {
    e.preventDefault();
    if (ph.length !== 12) {
      setError("Invalid Phone number");
      return false;
    }
    setLoading(true);
    onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;
    const formatPh = "+" + ph;
    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sended successfully!");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        setUser(res.user);
        setLoading(false);
        navigation("/home", { replace: true });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSignup(event);
    }
  };
  const isValidPhoneNumber = (phone) => {
    const phoneDigits = phone.replace(/\D/g, "");
    console.log(phone.length);
    return phoneDigits.length === 12;
  };
  const handleChange = (phone) => {
    setPh(phone);
    if (isValidPhoneNumber(phone)) {
      setError("");
    } else {
      setError("Invalid Phone number");
    }
  };

  return (
    <section className="bg-[#f0eef7] flex items-center justify-center h-screen ">
      <div className="flex items-center justify-center ">
        <div className="flex items-center bg-white justify-center w-[1000px] rounded-lg shadow-lg border-solid border-[1px] p-2">
          <img
            src={loginimage} // Update this with the correct path
            alt="Description of image"
            className="h-[350px] w-[30vw] object-cover rounded-lg "
          />
          <div className="flex flex-col items-center gap-4 w-[450px] pt-14 pb-14">
    <Toaster toastOptions={{ duration: 4000 }} />
    <div id="recaptcha-container"></div>
    <h1 className="text-center leading-normal text-primary-10 font-medium text-3xl mb-2">
        Welcome to  
        <span className="ml-2 text-primary-10 font-bold text-[#674FA3]">
            Bill Karlo
        </span>
    </h1>
    {showOTP ? (
        <>
            <div className="bg-white text-primary-1 w-fit mx-auto p-4 rounded-full">
                <BsFillShieldLockFill size={60} />
            </div>
            <label
                htmlFor="otp"
                className="font-bold text-xl text-black text-center"
            >
                Enter your OTP
            </label>
            <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span> </span>}
                containerStyle={{
                    gap: "7px",
                    marginTop: "10px",
                }}
                inputStyle={{
                    border: "1px solid",
                    width: "40px",
                    height: "40px",
                    borderRadius: "5px",
                    borderColor: "#4F378B",
                }}
                renderInput={(props) => <input {...props} />}
                shouldAutoFocus
            />

            <button
                onClick={onOTPVerify}
                className="bg-[#674FA3] w-[300px] mt-[10px] flex gap-1 items-center justify-center py-2.5 text-white rounded"
            >
                {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                <span>Verify OTP</span>
            </button>
        </>
    ) : (
        <form onSubmit={onSignup} className="flex flex-col items-center">
            <div className="bg-white text-emerald-500 mx-auto rounded-full">
                <img
                    src={logo}
                    alt="logo"
                    className="h-[100px] w-[100px] object-contain mb-5"
                />
            </div>
            <PhoneInput
                inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                }}
                country={"in"}
                value={ph}
                onChange={handleChange}
                onEnterKeyPress={handleKeyPress}
            />
            {error && (
                <div
                    className="text-red-500 text-[12px]"
                    style={{ color: "red" }}
                >
                    {error}
                </div>
            )}

            <button
                disabled={error}
                type="submit"
                className="bg-[#674FA3] w-[300px] flex gap-1 items-center justify-center py-2 text-white rounded mt-4"
            >
                {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                <span>Send Verification Code</span>
            </button>
        </form>
    )}
</div>

        </div>
      </div>
    </section>


  );
};

export default LoginPage;
