import React, { useState } from "react";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import Chart from "react-apexcharts";
const Reports = () => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Sales",
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      title: {
        text: "Monthly Sales Data",
        align: "left",
        style: {
          fontSize: "24px",
          fontWeight: "bold",
          color: "#333",
        },
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      markers: {
        size: 5,
        colors: ["#FFA41B"],
        strokeColors: "#fff",
        strokeWidth: 2,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
        ],
        title: {
          text: "Month",
        },
      },
      yaxis: {
        title: {
          text: "Sales",
        },
        min: 0,
        max: 140,
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
      colors: ["#546E7A"],
    },
  });
  const [barChartData] = useState({
    series: [
      {
        name: "Revenue",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
        ],
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `$ ${val} thousands`;
          },
        },
      },
    },
  });
  const [piechartData] = useState({
    series: [44, 55, 13, 43, 22],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  return (
    <div>
      <Header />
      <div className="flex">
        <SideBar />
        <div className="bg-gray-100 mb-4 rounded-lg shadow">
          <div className="p-4">
            <div className="flex justify-between items-center">
            
              {/* <h5>{order.orderDate.slice(0, 19)}</h5> */}
            </div>
            <table className="w-full mt-4 text-left text-sm">
              <thead>
                <tr>
                  <th className="px-2 py-1">Image</th>
                  <th className="px-2 py-1">Item</th>
                  <th className="px-2 py-1">Price Type</th>
                  <th className="px-2 py-1">Price Details</th>
                  <th className="px-2 py-1">Qty</th>
                  <th className="px-2 py-1">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-2 py-1">
                    {/* <img
                      src={item.imageUrl}
                      alt={item.itemName}
                      className="h-10 w-10 object-cover rounded-md"
                    /> */}
                  </td>
                  <td className="px-2 py-1">Cup Cake</td>
                  <td className="px-2 py-1">Single</td>
                  <td className="px-2 py-1">50</td>
                  <td className="px-2 py-1">3</td>
                  <td className="px-2 py-1">
                    <b>70</b>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div className="bg-gray-200 p-4 text-right rounded-b-lg">
            <b>Total : 670</b>
          </div>
        </div>
        <div className="w-[95%]">
          <div className="flex justify-between">
            <div className="bg-white p-6 rounded-lg shadow-lg w-[55%] ml-4">
              <Chart
                options={chartData.options}
                series={chartData.series}
                type="line"
                height={350}
              />
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg w-[40%]">
              <Chart
                options={barChartData.options}
                series={barChartData.series}
                type="bar"
                height={350}
              />
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg ml-4 mt-4">
            <Chart
              options={piechartData.options}
              series={piechartData.series}
              type="pie"
              width={380}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
