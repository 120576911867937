import React from "react";

const ShippingPolicy = () => {
  return (
    <div>
      <h1 className="text-center text-[40px] font-bold">Shipping Policy</h1>
      <br />
      <span className="font-bold">1. Introduction</span>
      <br />
      At BillKarlo, we strive to provide the best possible service to our
      customers, including efficient and timely delivery of any physical
      products or materials associated with our services. This Shipping Policy
      outlines our shipping procedures and policies.
      <br />
      <span className="font-bold">2.Order Processing</span>
      <br />
      2.1 Order Confirmation: Upon placing an order for any physical products,
      you will receive an email confirmation with details of your order.
      <br />
      2.2 Order Processing Time: Orders are processed within 1-2 business days.
      Orders are not processed or shipped on weekends or holidays.
      <br />
      <span className="font-bold">3. Shipping Methods and Delivery</span>
      <br />
      3.1 Shipping Methods: We offer various shipping options, including
      standard and expedited shipping. The available options and respective
      costs will be displayed at checkout.
      <br />
      3.2 Delivery Times: Delivery times vary based on your location and the
      chosen shipping method. Estimated delivery times are as follows: •
      Standard Shipping: 5-7 business days • Expedited Shipping: 2-3 business
      days
      <br />
      3.3 Shipping Charges: Shipping charges are calculated based on the weight
      of the package and the destination. The total shipping cost will be
      displayed at checkout.
      <br />
      <span className="font-bold">4. Shipping Destinations</span>
      <br />
      We currently ship to addresses within India. International shipping
      options are not available at this time.
      <br />
      <span className="font-bold">5. Order Tracking</span> <br />
      5.1 Tracking Information: Once your order has been shipped, you will
      receive an email with tracking information. You can use this information
      to track your order through the carrier’s website.
      <br />
      5.2 Lost or Delayed Shipments: If your order is delayed or lost in
      transit, please contact us immediately. We will work with the carrier to
      resolve the issue as quickly as possible.
      <br />
      <span className="font-bold">6. Shipping Restrictions</span> <br />
      6.1 P.O. Boxes: We do not ship to P.O. Boxes. Please provide a physical
      address for delivery. <br />
      6.2 Restricted Areas: We do not ship to certain restricted areas within
      India. If your address falls within a restricted area, you will be
      notified at checkout.
      <br />
      <span className="font-bold">7. Damaged or Defective Products</span> <br />
      7.1 Inspection: Please inspect your order upon delivery. If you receive a
      damaged or defective product, contact us within 48 hours of receiving your
      order. <br />
      7.2 Replacement or Refund: We will arrange for a replacement or refund for
      damaged or defective products at no additional cost to you. <br />
      <span className="font-bold">8. Return Shipping</span>
      <br />
      8.1 Return Policy: For information on how to return a product, please
      refer to our Return Policy.
      <br />
      8.2 Return Shipping Costs: Customers are responsible for return shipping
      costs unless the product is defective or damaged.
      <br />
      <span className="font-bold">9. Contact Information</span> <br />
      If you have any questions or concerns about our Shipping Policy, please
      contact us at <br />
      <span className="font-bold">
        <br />
        Bill Karlo
      </span>{" "}
      <br />
      billkarlo.builders@gmail.com <br />
      +91 9381403246
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default ShippingPolicy;
