import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import { Button, Input, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  runTransaction,
  onSnapshot,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { FaUser } from "react-icons/fa";
import { db } from "../firebase";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, setCartEmpty } from "../redux/slices/cartSlice";
import Drawer from "@mui/material/Drawer";
import Cart from "../components/Cart";
const TableManagement = () => {
  const params = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = getAuth();
  const cartItems = useSelector((state) => state.cart.cart);
  const [open, setOpen] = useState(false);
  const [tableNumber, setTableNumber] = useState("");
  const [noOfSeats, setnoOfSeats] = useState("");
  const [tables, setTables] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableDrawer, setTableDrawer] = useState(false);
  const [tableItems, setTableItems] = useState([]);
  const [tableItemUpdate, setTableItemUpdate] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleTableDrawer = () => { };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",

    boxShadow: 24,
    p: 4,
  };
  // functions
  const addTableToFirestore = async () => {
    const user = auth.currentUser;
    if (!user) return;

    const shopsRef = collection(db, "shop");
    const shopUid = user.uid;
    const tablesRef = collection(shopsRef, shopUid, "tables");
    const tableNum = parseInt(tableNumber);

    try {
      console.log(`Checking if table number ${tableNum} exists`);
      const q = query(tablesRef, where("number", "==", tableNum));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        console.log("table Already exist");
      } else {
        const newTableRef = doc(tablesRef);
        const tablePayload = {
          tableId: newTableRef.id,
          number: tableNum,
          noOfSeats: parseInt(noOfSeats),
          status: "available",
          items: [],
        };
        await setDoc(newTableRef, tablePayload);
        console.log(`Table number ${tableNum} added successfully`);
        console.log(
          `Table added to Firestore under shop UID ${shopUid} with ID: ${newTableRef.id}`
        );
        setnoOfSeats("");
        setTableNumber("");
        getTables();
        setOpen(false);
      }
    } catch (error) {
      console.error("Error adding table to Firestore:", error);
    }
  };
  const getTables = () => {
    setTableLoading(true);
    const user = auth.currentUser;
    if (!user) return;

    const shopUid = user.uid;
    const tablesRef = collection(db, "shop", shopUid, "tables");

    const q = query(tablesRef, orderBy("number", "asc"));

    // Set up the snapshot listener
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const tablesList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(tablesList);
        setTables(tablesList);
        setTableLoading(false);
      },
      (error) => {
        console.error("Error fetching tables:", error);
        setTableLoading(false);
      }
    );

    // Return the unsubscribe function to clean up the listener
    return unsubscribe;
  };
  const updateItemsInTable = async (tableId) => {
    if (cartItems.length === 0) {
      return;
    }
    const user = auth.currentUser;
    if (!user) return;

    const shopUid = user.uid;
    const tableRef = doc(db, "shop", shopUid, "tables", tableId);

    try {
      const tableDoc = await getDoc(tableRef);
      if (tableDoc.exists()) {
        const currentItems = tableDoc.data().items || [];
        const updatedItems = [...currentItems, ...cartItems];
        await updateDoc(tableRef, {
          items: updatedItems,
        });
      }
      getTables();
      console.log("added Successfull");
      dispatch(setCartEmpty());
    } catch (error) {
      console.log(error);
    }
  };
  const incrementTableItemQuantity = async (itemId, tableId) => {
    const tableDocRef = doc(
      db,
      "shop",
      auth.currentUser.uid,
      "tables",
      tableId
    );
    try {
      await runTransaction(db, async (transaction) => {
        const tableDoc = await transaction.get(tableDocRef);
        if (!tableDoc.exists()) {
          throw new Error("Table does not exist!");
        }
        const data = tableDoc.data();
        const items = data.items || [];

        const updatedItems = items
          .map((item) => {
            if (item.priceDetails.id === itemId) {
              if (item.quantity - 1 < 0) {
                return {
                  ...item,
                  quantity: item.quantity + 1,
                };
              } else {
                return null;
              }
            }
            return item;
          })
          .filter((item) => item !== null);

        transaction.update(tableDocRef, { items: updatedItems });
      });
      console.log("Cart item quantity updated successfully.");
    } catch (error) {
      console.error("Error updating cart item quantity:", error);
    }
  };
  const decrementTableItemQuantity = async (itemId, tableId) => {
    const tableDocRef = doc(
      db,
      "shop",
      auth.currentUser.uid,
      "tables",
      tableId
    );
    try {
      await runTransaction(db, async (transaction) => {
        const tableDoc = await transaction.get(tableDocRef);
        if (!tableDoc.exists()) {
          throw new Error("Table does not exist!");
        }
        const data = tableDoc.data();
        const items = data.items || [];

        const updatedItems = items
          .map((item) => {
            if (item.priceDetails.id === itemId) {
              if (item.quantity - 1 > 0) {
                return {
                  ...item,
                  quantity: item.quantity - 1,
                };
              } else {
                return null;
              }
            }
            return item;
          })
          .filter((item) => item !== null);

        transaction.update(tableDocRef, { items: updatedItems });
      });
      console.log("Cart item quantity updated successfully.");
    } catch (error) {
      console.error("Error updating cart item quantity:", error);
    }
  };
  useEffect(() => {
    const unsubscribe = getTables();
    return () => unsubscribe();
  }, []);

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1">
        <SideBar />
        <div className="flex-1 m-3">
          <div className="flex justify-between items-center ml-5 mr-5">
            <h3 className="mb-3 text-2xl"><b className="text-400">Table Management</b></h3>
            <button
              className="bg-white p-1  text-purple-900 rounded-[60px] px-[20px] flex gap-2 items-center font-semibold hover:bg-purple-800 hover:text-white outline outline-2 outline-purple-900"
              onClick={handleOpen}
            >
              + Add Table
            </button>


          </div>

          <div className="flex justify-between items-center">


            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <h1 className="font-bold text-center pb-2 text-[18px]">
                  Add New Table
                </h1>
                <div className="flex flex-col gap-4">
                  <TextField
                    sx={{ width: "100%" }}
                    size="small"
                    variant="outlined"
                    label="Table number"
                    onChange={(e) => setTableNumber(e.target.value)}
                  />
                  <TextField
                    sx={{ width: "100%" }}
                    size="small"
                    variant="outlined"
                    label="Number of Seats"
                    onChange={(e) => setnoOfSeats(e.target.value)}
                  />
                </div>
                <div className="w-[100%] flex  gap-2  mt-3  justify-between items-center ">
                  <Button variant="contained" size="small">
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    className="bg-purple-950"
                    onClick={addTableToFirestore}
                  >
                    Add
                  </Button>
                </div>
              </Box>
            </Modal>
          </div>
          {/* displaying tables  */}
          <div className="">
            <div className="flex mb-4 flex-wrap">
              {tableLoading ? (
                <p>Loading....</p>
              ) : (
                tables.map((e) => (
                  <div
                    className="w-[calc(33.333%-1rem)] m-2 bg-white p-4 rounded-lg shadow-md"
                    key={e.id}
                  >
                    <div className="card ">
                      <div className="flex justify-between items-center">
                        <p>Table {e.number}</p>
                        <div className="flex items-center gap-1">
                          <FaUser className="text-gray-500" />
                          {e.noOfSeats}
                        </div>

                      </div>
                      <div className="bg-gray-100 p-2 mb-2 h-[20vh] overflow-y-auto">
                        <div className="flex justify-between font-bold mb-1">
                          <span>Item</span>
                          <span>Qty</span>
                        </div>
                        {e.items.length > 0 ? (
                          e.items.map((item, i) => (
                            <div key={i} className="flex justify-between mb-1">
                              <span>{item.itemName}</span>
                              <span>
                                {item.quantity} {item.priceDetails.priceType}
                              </span>
                            </div>
                          ))
                        ) : (
                          <p className="text-center text-gray-500">No items in this table</p>
                        )}
                      </div>

                      <div className="card-footer text-center text-red-700">
                        {e.items.length > 0 ? (
                          <b className="text-red-500">Occupied</b>
                        ) : (
                          <b className="text-green-500">Available</b>
                        )}
                        <div className="flex justify-between items-center mt-2">
                          <Button
                            variant="contained"
                            size="small"
                            className="bg-purple-500 text-white"
                            onClick={() => updateItemsInTable(e.id)}
                          >
                            Add Items
                          </Button>

                          <Button
                            variant="contained"
                            size="small"
                            className="bg-purple-500 text-white"
                            onClick={() => {
                              dispatch(addToCart(e.items));
                              setTableItems(e);
                              setTableDrawer(true);
                            }}
                          >
                            Print
                          </Button>
                        </div>

                        <Drawer
                          anchor={"right"}
                          open={tableDrawer}
                          onClose={() => setTableDrawer(false)}
                        >
                          <div className="w-[40dvw]">
                            <Cart
                              width={"100%"}
                              table={true}
                              data={tableItems}
                              setTableDrawer={setTableDrawer}
                            />
                          </div>
                        </Drawer>
                      </div>

                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableManagement;
