import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import SideBar from "../components/SideBar";
import Header from "../components/Header";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  addDoc,
  updateDoc,
  getDoc,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { db } from "../firebase";
import { v4 as uuidv4 } from "uuid";
import { MdDelete } from "react-icons/md";
const AddShopItems = () => {
  const user = getAuth().currentUser.uid;
  const storage = getStorage();
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [data, setData] = useState([]);
  const [itemId, setItemId] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [itemCodeError, setItemCodeError] = useState("");
  const [priceDetails, setPriceDetails] = useState([
    { id: uuidv4(), priceType: "", price: "" },
  ]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [priceDetailsError, setPriceDetailsError] = useState([]);
  const [submiLoader, setSubmitLoader] = useState(false);
  const [quantity, setQuantity] = useState(null);
  const [isFavorite, setIsfavourite] = useState(false);
  const handleChangePriceDetails = (e, i) => {
    const { name, value } = e.target;
    const newPriceDetails = priceDetails.map((item, index) => {
      if (index === i) {
        return {
          ...item,
          [name]: name === "price" ? parseInt(value, 10) : value,
        };
      }
      return item;
    });
    setPriceDetails(newPriceDetails);
  };

  const handleAddPriceDetails = () => {
    setPriceDetails([
      ...priceDetails,
      { id: uuidv4(), priceType: "", price: "" },
    ]);
    setPriceDetailsError([...priceDetailsError, {}]);
  };

  const handleDeletePriceDetail = (id) => {
    const updatedPriceDetails = priceDetails.filter((item) => item.id !== id);
    setPriceDetails(updatedPriceDetails);
  };
  const validatePriceDetails = () => {
    const errors = priceDetails.map((field) => ({
      priceType: !field.priceType ? "Price type is required" : "",
      price:
        !field.price || field.price <= 0
          ? "Price must be a positive number"
          : "",
    }));
    setPriceDetailsError(errors);
    return errors.every((error) => !error.priceType && !error.price);
  };

  const handleAddShopItem = async () => {
    try {
      if (name === "") {
        setNameError("Please enter name");
        return false;
      } else {
        setNameError("");
      }
      if (category === "") {
        setCategoryError("Please enter category");
        return false;
      } else {
        setCategoryError("");
      }
      if (!itemCode) {
        setItemCodeError("Please enter item code");
        return false;
      } else {
        setItemCodeError("");
      }
      if (validatePriceDetails()) {
        console.log("valid");
        setSubmitLoader(true);
        const shopRef = doc(db, "shop", user);
        const shopItemref = collection(shopRef, "shopItems");
        let downloadUrl = imageUrl;
        if (imageUrl instanceof File) {
          console.log("Entered this condition");
          const userFolderRef = ref(storage, "shopItems/" + user);
          const storageRef = ref(userFolderRef, imageUrl.name);
          await uploadBytes(storageRef, imageUrl);
          const imageLink = await getDownloadURL(storageRef);
          downloadUrl = imageLink;
        }
        if (itemId) {
          console.log("entered to updated");
          const updateShopItemref = doc(db, "shop", user, "shopItems", itemId);
          const updateItem = await updateDoc(updateShopItemref, {
            name,
            category,
            itemCode,
            imageUrl: downloadUrl,
            priceDetails,
            isFavorite,
            quantity,
          });
          toast.success("Item updated Successfull", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setSubmitLoader(false);
        } else {
          const updatedPriceDetails = priceDetails.map((detail) => ({
            ...detail,
            id: uuidv4(),
          }));
          console.log(updatedPriceDetails);
          const newDoc = await addDoc(shopItemref, {
            name,
            category,
            itemCode,
            imageUrl: downloadUrl,
            priceDetails: updatedPriceDetails,
            isFavorite,
            quantity,
          });
          await setDoc(newDoc, { id: newDoc.id }, { merge: true });
          toast.success("Item Added Successfull", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.log("Shop item added successfully!", newDoc.id);
        }
        setSubmitLoader(false);
      }
    } catch (error) {
      console.error("Error adding shop item:", error);
      setSubmitLoader(false);
      toast.error("Something went wrong..", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const getServerItems = async () => {
    // setLoading(true);
    if (user) {
      const serverItemsCollection = doc(db, "shop", user);
      const shopItemref = collection(serverItemsCollection, "shopItems");
      // console.log(shopItemref);
      const q = query(shopItemref, orderBy("itemCode", "asc"));
      const querySnapshot = await getDocs(q);
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      setData(items);
    }
    // setLoading(false);
  };
  const deleteShopItem = async (itemId) => {
    try {
      const serverItemsCollection = doc(db, "shop", user);
      const shopItemref = collection(serverItemsCollection, "shopItems");
      const shopItemDocRef = doc(shopItemref, itemId);
      // const shopItemRef = doc(db, "shop", shopId, "shopitem", itemId);
      await deleteDoc(shopItemDocRef);
      const updatedItemsCollection = doc(db, "shop", user);
      const updatedItemsRef = collection(updatedItemsCollection, "shopItems");
      const q = query(updatedItemsRef, orderBy("itemCode"));
      const querySnapshot = await getDocs(q);
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      // dispatch(setAddShopLoader());
    } catch (error) {
      console.error("Error deleting shop item:", error);
    }
  };
  useEffect(() => {
    getServerItems();
  }, [data]);

  return (
    <div className="h-screen">
      <Header />
      <div className="flex flex-wrap">
        <SideBar />
        <div className="p-1 w-[30%] h-[calc(100vh-64px)] overflow-y-scroll">
          <ToastContainer />
          <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg ml-2 mt-2">
            {/* <ToastContainer /> */}
            <div className="flex justify-between"></div>
            <div
              className={`flex flex-col gap-4 pt-2 ${
                isFormDisabled && "pointer-events-none"
              }`}
            >
              <div className="h-[30px]">
                {itemId.length > 0 && (
                  <p className="text-red-400 text-[12px]">
                    You are selected existing item to update. If you want to add
                    as new item{" "}
                    <span
                      className="text-blue-700 cursor-pointer"
                      onClick={() => setItemId("")}
                    >
                      Click here..
                    </span>
                  </p>
                )}
              </div>
              {imageUrl && (
                <div className="w-72 mx-auto">
                  <img
                    src={
                      imageUrl instanceof File
                        ? URL.createObjectURL(imageUrl)
                        : imageUrl
                    }
                    alt="Selected"
                    className="w-full rounded-lg h-[100px] w-[100px] object-contain"
                  />
                </div>
              )}

              <label
                htmlFor="doc"
                className="flex items-center p-4 gap-3 rounded-3xl border border-gray-300 border-dashed bg-gray-50 cursor-pointer"
              >
                <img
                  className="h-[2rem] w-auto object-cover"
                  src="https://e7.pngegg.com/pngimages/637/822/png-clipart-font-awesome-upload-computer-icons-font-computers-blue-text.png"
                  alt=""
                />
                <div className="space-y-2">
                  <h4 className="text-base font-semibold text-gray-700">
                    Upload an Image
                  </h4>
                </div>
                <input
                  type="file"
                  id="doc"
                  name="doc"
                  hidden
                  onChange={(e) => setImageUrl(e.target.files[0])}
                />
              </label>
              <div>
                <input
                  placeholder="Enter item name"
                  className="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                {nameError && (
                  <p className="text-red-500 text-[13px] pl-1">{nameError}</p>
                )}
              </div>
              <div>
                <input
                  placeholder="Category"
                  className="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none"
                  onChange={(e) => setCategory(e.target.value)}
                  value={category}
                />
                {categoryError && (
                  <p className="text-red-500 text-[13px] pl-1">
                    {categoryError}
                  </p>
                )}
              </div>
              <div>
                <input
                  placeholder="Item code"
                  className="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none"
                  type="number"
                  onChange={(e) => setItemCode(parseInt(e.target.value))}
                  value={itemCode}
                />
                {itemCodeError && (
                  <p className="text-red-500 text-[13px] pl-1">
                    {itemCodeError}
                  </p>
                )}
              </div>
              <div>
                <div>
                  {priceDetails.map((field, i) => (
                    <div
                      className="py-2 flex gap-2 items-center"
                      key={field.id}
                    >
                      <div>
                        <input
                          placeholder="Price Type"
                          name="priceType"
                          className="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none"
                          value={field.priceType}
                          onChange={(e) => handleChangePriceDetails(e, i)}
                        />
                        {priceDetailsError[i]?.priceType && (
                          <p className="text-red-500 text-[10px] pl-1">
                            {priceDetailsError[i].priceType}
                          </p>
                        )}
                      </div>
                      <div>
                        <input
                          placeholder="Price"
                          name="price"
                          className="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none"
                          type="number"
                          value={field.price}
                          onChange={(e) => handleChangePriceDetails(e, i)}
                        />
                        {priceDetailsError[i]?.price && (
                          <p className="text-red-500 text-[10px] pl-1">
                            {priceDetailsError[i].price}
                          </p>
                        )}
                      </div>
                      {i !== 0 && (
                        <button
                          onClick={() => handleDeletePriceDetail(field.id)}
                        >
                          <IoIosCloseCircleOutline size={25} color="red" />
                        </button>
                      )}
                    </div>
                  ))}
                </div>
                <div className="flex gap-2 justify-center py-2">
                  <button
                    onClick={handleAddPriceDetails}
                    className="bg-purple-800 hover:bg-purple-900 border-none rounded-md p-1 font-semibold text-white px-5 py-2 text-[13px]"
                    disabled={isFormDisabled}
                  >
                    Add More Price Details
                  </button>
                </div>
              </div>

              {!itemId.length > 0 ? (
                <button
                  onClick={handleAddShopItem}
                  className="bg-green-500 hover:bg-green-800 px-5 font-semibold text-white rounded-md border-none p-1 w-[150px] mx-auto px-5 py-2 text-[13px]"
                  disabled={isFormDisabled}
                >
                  Save Item
                </button>
              ) : (
                <button
                  onClick={handleAddShopItem}
                  className="bg-yellow-500 hover:bg-yellow-800 px-5 font-semibold text-white rounded-md border-none p-1 w-[150px] mx-auto px-5 py-2 text-[13px]"
                  disabled={isFormDisabled}
                >
                  Update Item
                </button>
              )}
            </div>
          </div>
        </div>
        <div
          className={
            true
              ? "w-[65%] flex justify-center flex-wrap gap-2 pt-4 overflow-y-scroll  h-[calc(100vh-64px)]"
              : "flex flex-wrap gap-2 mt-4 overflow-y-scroll"
          }
        >
          {data.map((e) => (
            <div
              key={e.id}
              className="w-[200px] border border-solid px-4 py-2 rounded-md shadow-lg cursor-pointer"
              onClick={() => {
                setSelectedItem(e);
                setName(e.name);
                setCategory(() => e.category);
                setImageUrl(() => e.imageUrl);
                setItemCode(() => e.itemCode);
                setPriceDetails(() => e.priceDetails);
                setItemId(() => e.id);
              }}
            >
              <h4 className="font-bold text-[14px]">{e.category}</h4>
              <img
                src={e.imageUrl}
                alt={e.id}
                className="h-[150px] w-[200px] object-cover rounded-md"
              />
              <h1 className="text-[13px]">{e.name}</h1>
              <div className="flex justify-between items-center my-2">
                <p className="font-bold text-green-600">
                  <span className="font-semibold text-black mr-1">Code:</span>
                  {e.itemCode}
                </p>
                <MdDelete
                  size={20}
                  className={false ? "pointer-events-none" : "cursor-pointer"}
                  onClick={(event) => {
                    event.stopPropagation();
                    deleteShopItem(e.id);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddShopItems;
