import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bluetoothCharacteristic: null,
  devices: [],
  openConnectModal: false,
};

const bluetoothSlice = createSlice({
  name: "bluetooth",
  initialState,
  reducers: {
    setCharectarstice: (state, action) => {
      state.bluetoothCharacteristic = action.payload;
    },
    setDevices: (state, action) => {
      state.devices = [...state.devices, action.payload];
    },
    setOpenConnecteModal: (state, action) => {
      state.openConnectModal = action.payload;
    },
  },
});

export const { setCharectarstice, setDevices, setOpenConnecteModal } =
  bluetoothSlice.actions;
export default bluetoothSlice.reducer;
