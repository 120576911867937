import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: [],
  itemsWithPriceDetails: [],
  item: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const newItem = action.payload;
      if (newItem?.source === "FromHome") {
        const existingItem = state.cart.find(
          (item) => item.priceDetails.id === newItem.priceDetails.id
        );
        if (existingItem) {
          existingItem.quantity++;
        } else {
          state.cart.push({ ...newItem, quantity: 1 });
        }
      } else {
        state.cart = newItem;
      }
    },
    addItemWithPriceDetails: (state, action) => {
      if (
        !state.itemsWithPriceDetails.some((item) => item === action.payload)
      ) {
        state.itemsWithPriceDetails.push(action.payload);
      }
    },
    removeItemWithPriceDetails: (state, action) => {
      const itemIdToRemove = action.payload;
      state.itemsWithPriceDetails = state.itemsWithPriceDetails.filter(
        (itemId) => itemId !== itemIdToRemove
      );
    },
    removeFromCart: (state, action) => {
      const newItem = action.payload;
      const existingItemIndex = state.cart.findIndex(
        (item) => item.priceDetails.id === newItem.priceDetails.id
      );
      if (existingItemIndex !== -1) {
        if (state.cart[existingItemIndex].quantity > 1) {
          state.cart[existingItemIndex].quantity--;
        } else {
          state.cart.splice(existingItemIndex, 1); // Remove the item if quantity reaches 0
        }
      }
    },
    updateQuanity: (state, action) => {
      const { id } = action.payload;
      console.log(id);
      const existingItem = state.cart.find(
        (item) => item.priceDetails.id === id
      );
      console.log(existingItem);
      if (existingItem) {
        existingItem.quantity++;
      }
    },
    decreasQuantity: (state, action) => {
      const { id } = action.payload;
      const existingItem = state.cart.find(
        (item) => item.priceDetails.id === id
      );
      if (existingItem.quantity > 1) {
        existingItem.quantity--;
      } else if (existingItem.quantity <= 1) {
        const existingItemIndex = state.cart.findIndex(
          (item) => item.priceDetails.id === id
        );
        state.cart.splice(existingItemIndex, 1);
      }
    },
    setCartEmpty: (state, action) => {
      state.cart = [];
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  addItemWithPriceDetails,
  addItem,
  removeItemWithPriceDetails,
  updateQuanity,
  decreasQuantity,
  setCartEmpty,
} = cartSlice.actions;
export default cartSlice.reducer;
