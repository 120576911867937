import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyCV0pCqswZ0SpZBmRK0QoFCl_7p1Ksj1bw",
  authDomain: "token-fefde.firebaseapp.com",
  projectId: "token-fefde",
  storageBucket: "token-fefde.appspot.com",
  messagingSenderId: "426298954855",
  appId: "1:426298954855:web:8b10548810da5e0ef3f0c2",
  measurementId: "G-J19MD8BLH5",
};
const app = initializeApp(firebaseConfig);
// console.log(app);
const messaging = getMessaging(app);
// console.log(messaging);
const db = getFirestore(app);
const auth = getAuth(app);
// auth.settings.appVerificationDisabledForTesting = true;
// console.log(db);
export { messaging, db, auth };
