import React from "react";

const Privacy = () => {
  return (
    <div>
      <h1 className="text-center font-bold text-[40px]">Privacy Policy</h1>
      <br />
      <span className="font-bold">1. Introduction</span>
      <br />
      At BillKarlo, we are committed to protecting your privacy and ensuring the
      security of your personal information. This Privacy Policy outlines our
      practices regarding the collection, use, and disclosure of your
      information when you use our services.
      <br />
      <span className="font-bold">2. Information We Collect</span>
      <br />
      2.1 Personal Information: When you create an account or use our services,
      we may collect personal information such as your name, email address,
      phone number, and billing information.
      <br />
      2.2 Usage Data: We collect information about how you use our services,
      including your IP address, browser type, device information, pages
      visited, and the time and date of your visits.
      <br />
      2.3 Cookies and Tracking Technologies: We use cookies and similar tracking
      technologies to enhance your experience on our website. Cookies are small
      data files stored on your device.
      <br />
      <span className="font-bold"> 3. How We Use Your Information</span>
      <br />
      3.1 To Provide and Maintain Our Services: We use your information to
      create and manage your account, process transactions, and provide customer
      support.
      <br /> 3.2 To Improve Our Services: We analyze usage data to understand
      how our services are used and to make improvements.
      <br />
      3.3 To Communicate With You: We may use your information to send you
      updates, newsletters, and promotional materials. You can opt-out of these
      communications at any time.
      <br />
      3.4 To Ensure Security: We use your information to monitor and protect the
      security of our services and to detect and prevent fraud or other illegal
      activities <br />
      <span className="font-bold"> 4. Sharing Your Information</span>
      <br />
      4.1 Third-Party Service Providers: We may share your information with
      third-party service providers who assist us in operating our services,
      such as payment processors and cloud hosting providers. These providers
      have access to your information only to perform specific tasks on our
      behalf and are obligated to protect your information.
      <br />
      4.2 Legal Requirements: We may disclose your information if required to do
      so by law or in response to valid requests by public authorities (e.g., a
      court or government agency).
      <br />
      <span className="font-bold">5. Data Security </span>
      <br />
      We implement robust security measures to protect your data from
      unauthorized access, alteration, disclosure, or destruction. These
      measures include encryption, access controls, and regular security
      assessments.
      <br />
      <span className="font-bold"> 6. Data Retention: </span>
      <br />
      We retain your personal information only for as long as necessary to
      fulfill the purposes outlined in this Privacy Policy. We may also retain
      and use your information to comply with legal obligations, resolve
      disputes, and enforce our agreements.
      <br />
      <span className="font-bold">7. Your Rights:</span>
      <br /> 7.1 Access and Update: You have the right to access and update your
      personal information at any time through your account settings.
      <br />
      7.2 Delete: You can request the deletion of your personal information by
      contacting us. We will take reasonable steps to delete your information
      unless we are required to retain it for legal reasons.
      <br />
      7.3 Opt-Out: You can opt-out of receiving promotional communications from
      us by following the unsubscribe instructions included in these
      communications or by contacting us directly.
      <br />
      <span className="font-bold">8. Children's Privacy:</span>
      <br /> Our services are not intended for children under the age of 18. We
      do not knowingly collect personal information from children under 18. If
      you are a parent or guardian and believe that your child has provided us
      with personal information, please contact us so that we can delete such
      information.
      <br />
      Business Transfers: If BillKarlo is involved in a merger, acquisition, or
      asset sale, your information may be transferred as part of that
      transaction. We will notify you before your information is transferred and
      becomes subject to a different privacy policy
      <br />
      <span className="font-bold">9. Changes to This Privacy Policy:</span>
      <br />
      We may update this Privacy Policy from time to time. The most current
      version will always be posted on our website. We encourage you to review
      this Privacy Policy periodically for any changes. Your continued use of
      our services after any changes to this Privacy Policy will constitute your
      acknowledgment and acceptance of the changes.
      <br />
      <span className="font-bold">10. Contact Us</span>
      <span className="font-bold">
        <br />
        Bill Karlo
      </span>{" "}
      <br />
      billkarlo.builders@gmail.com <br />
      +91 9381403246
      <br />
      If you have any questions about this Privacy Policy or our data practices,
      please contact us at: billkarlo.builders@gmail.com
      <br />
    </div>
  );
};

export default Privacy;
