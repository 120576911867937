import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { FaReceipt } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { MdDeleteForever, MdHome, MdOutlineDone } from "react-icons/md";
import { format } from "date-fns";
import { IoMdAddCircleOutline, IoMdSearch } from "react-icons/io";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import { Box, Button, Card, Input, Modal, Typography } from "@mui/material";
import { db } from "../firebase";
import {
  IoCaretDownCircleOutline,
  IoCloseCircleOutline,
} from "react-icons/io5";
import { LuMinusCircle } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemWithPriceDetails,
  addToCart,
  decreasQuantity,
  removeFromCart,
  removeItemWithPriceDetails,
  setCartEmpty,
  updateQuanity,
} from "../redux/slices/cartSlice";
import { setUser } from "../redux/slices/userSlice";
import { BiPrinter } from "react-icons/bi";
import { getAuth } from "firebase/auth";
import {
  Br,
  Cut,
  Line,
  Printer,
  render,
  Row,
  Text,
} from "react-thermal-printer";
import SideBar from "../components/SideBar";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  setDevices,
  setOpenConnecteModal,
} from "../redux/slices/bluetoothSlice";
import { MdTableBar } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  setBluetoothDevice,
  setUsbDeviceR,
} from "../redux/slices/printerDevicesSlice";
import Cart from "../components/Cart";
const HomePage = () => {
  const uid = getAuth().currentUser.uid;
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [menu, setMenu] = useState([]);
  const [activeIndices, setActiveIndices] = useState([]);
  const [activeFilterId, setActiveFilterId] = useState(null);
  const [category, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredMenu, setFilteredMenu] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [item, setItem] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [finalTotal, setFinalTotal] = useState();
  const [cashMode, setCashMode] = useState("CASH");
  const [addDiscount, setAddDiscount] = useState(false);
  const [discount, setDiscount] = useState(0);
  //bluetooth
  const [message, setMessage] = useState("");
  const [openBluetooth, setOpenBluetooth] = useState(true);
  //   const [devices, setDevices] = useState([]);
  const [bluetoothCharacteristic, setBluetoothCharacteristic] = useState(null);
  const [usbDevice, setUsbDevice] = useState(null);
  const [port, setPort] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [usbDevices, setUsbDevices] = useState([]);
  const [connected, setConnected] = useState(false);
  const [readData, setReadData] = useState("");
  const [tokenNumber, setTokenNumber] = useState(0);
  const devices = useSelector((state) => state.bluetooth.devices);
  const openConnectModal = useSelector(
    (state) => state.bluetooth.openConnectModal
  );
  const user = useSelector((state) => state.user.user);
  const cart = useSelector((state) => state.cart.cart);

  const getItems = async () => {
    setLoading(true);
    const serverItemsCollection = doc(db, "shop", uid);
    const getUser = doc(db, "users", uid);
    const user = (await getDoc(getUser)).data();
    dispatch(setUser(user));
    // const locked = doc(db, "shop", uid);
    const shopItemref = collection(serverItemsCollection, "shopItems");
    // console.log(shopItemref);
    const q = query(shopItemref);
    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ id: doc.id, ...doc.data() });
    });
    const uniqueCategories = [...new Set(items.map((item) => item.category))];
    setCategories(uniqueCategories);
    setFilteredMenu(items);
    setMenu(items);
    setLoading(false);
  };
  const basedOnCategoryShowItems = (itemsList, selectedCategory) => {
    if (!selectedCategory) {
      return itemsList;
    }

    return itemsList.filter(
      (item) => item.category.toLowerCase() === selectedCategory.toLowerCase()
    );
  };

  const filterItems = (selectedCategory) => {
    if (selectedCategories.includes(selectedCategory)) {
      setFilteredMenu(menu);
      setSelectedCategories([]);
    } else {
      const filteredItems = basedOnCategoryShowItems(menu, selectedCategory);
      setFilteredMenu(filteredItems);
      setSelectedCategories([...selectedCategories, selectedCategory]);
    }
  };
  const handleOpenModal = (e) => {
    setOpenDrawer(true);
    // const existingItem = item.find(
    //   (i) => i.priceDetails.id === e.priceDetails.id
    // );
    // console.log(existingItem);
    // if (existingItem) {
    //   existingItem.quantity++;
    //   console.log(existingItem);
    // } else {
    //   setItem(e);
    //   console.log(e);
    // }
    // // setItem(e);
    // dispatch(addItem(e));
    setItem(e);
    setSelectedItem(e);
  };
  const returnQuantity = (priceId) => {
    for (var element of cart) {
      if (element.priceDetails.id == priceId) {
        return element.quantity;
      }
    }
    return 0;
  };
  const addToCartHandler = (item, priceType) => {
    // console.log(item, priceType);
    // const newItem = [];

    // const existingItem = newItem.find(
    //   (i) => i.priceDetails.id === item.priceDetails.id
    // );
    // console.log(existingItem);
    // if (existingItem) {
    //   existingItem.quantity++;
    // } else {
    //   newItem.push({ ...newItem, quantity: 1 });
    // }

    dispatch(
      addToCart({
        itemId: item.id,
        category: item.category,
        imageUrl: item.imageUrl,
        quantity: item.quantity,
        itemName: item.name,
        priceDetails: priceType,
        itemCode: item.itemCode,
        source: "FromHome",
      })
    );
    // console.log(item.quantity);
    // console.log(item);
    // setItemWithPriceDetails([...itemWithPriceDetails, item.id]);
    dispatch(addItemWithPriceDetails(item.id));
    // setItem(item.quantity + 1);
  };

  const removeFromCartHandler = (item, priceType) => {
    dispatch(
      removeFromCart({
        id: item.id,
        category: item.category,
        imageUrl: item.imageUrl,
        quantity: item.quantity,
        name: item.name,
        priceDetails: priceType,
      })
    );
    const isItemInCart = cart.some((cartItem) => cartItem.id === item.id);
    console.log(isItemInCart);
    if (!isItemInCart) {
      // If item is not in cart, remove it from itemsWithPriceDetails
      dispatch(removeItemWithPriceDetails(item.id));
    }
  };
  const calculateQuantityPrize = (quantity, price) => {
    return quantity * price;
  };
  const calculateTotalCartCost = () => {
    return cart.reduce(
      (total, item) =>
        total + calculateQuantityPrize(item.quantity, item.priceDetails.price),
      0.0
    );
  };
  const disCountCalculateTotalCartCost = () => {
    if (discount > 0) {
      return (
        cart.reduce(
          (total, item) =>
            total +
            calculateQuantityPrize(item.quantity, item.priceDetails.price),
          0.0
        ) +
        returnGstTotal() -
        discount
      );
    }
  };
  const removeDisCountCalculateTotalCartCost = () => {
    return (
      cart.reduce(
        (total, item) =>
          total +
          calculateQuantityPrize(item.quantity, item.priceDetails.price),
        0.0
      ) + returnGstTotal()
    );
  };
  const returnSelected = (priceId) => {
    for (var element of cart) {
      if (element.itemId === priceId) {
        return true;
      }
    }
    return false;
  };

  //bluetooth

  const handleBluetoothConnect = async (device) => {
    try {
      const server = await device.gatt.connect();
      const service = await server.getPrimaryService(
        "000018f0-0000-1000-8000-00805f9b34fb"
      ); // Replace with your printer's service UUID
      const characteristic = await service.getCharacteristic(
        "00002af1-0000-1000-8000-00805f9b34fb"
      ); // Replace with your printer's characteristic UUID
      setBluetoothCharacteristic(characteristic);
      dispatch(setBluetoothDevice(characteristic));
      setMessage(`Connected to the Bluetooth printer: ${device.name}`);
      setOpenBluetooth(false);
    } catch (error) {
      console.error("Error:", error);
      setMessage("Failed to connect to the Bluetooth printer");
    }
  };
  async function bluetoothWriteDataInChunks(data) {
    const chunkSize = 512;
    let offset = 0;

    while (offset < data.length) {
      const chunk = data.slice(offset, offset + chunkSize);
      await bluetoothCharacteristic.writeValue(chunk);
      offset += chunkSize;
    }
  }
  async function usbWriteDataInChunks(data) {
    const chunkSize = 512;
    let offset = 0;
    while (offset < data.length) {
      const chunk = data.slice(offset, offset + chunkSize);
      await usbDevice.transferOut(1, chunk);
      offset += chunkSize;
    }
  }
  function getCurrentDateTime() {
    const date = new Date();

    // Extract components
    const day = date.getDate().toString().padStart(2, "0"); // Get day and ensure two digits
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (zero-based)
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0"); // Get hours and ensure two digits
    const minutes = date.getMinutes().toString().padStart(2, "0"); // Get minutes and ensure two digits

    // Format the date and time
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }
  const returnTotalQuantity = () => {
    return cart.reduce((total, item) => {
      return total + item.quantity;
    }, 0);
  };

  const handlePrintThankYou = async () => {
    const shopOrdersRef = collection(doc(db, "shop", user.uid), "shopOrders");
    const orderData = {
      items: cart,
      orderDate: format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
      shopId: user?.uid,
      totalItems: returnQuantity(),
      paymentMode: cashMode,
      shopId: user.uid,
      tableId: null,
      tableNumber: null,
      totalItems: cart?.length,
      grandTotal: addDiscount
        ? disCountCalculateTotalCartCost()
        : removeDisCountCalculateTotalCartCost(),
      totalPrice: calculateTotalCartCost(),
      discountAmount: discount,
      gstAmount: returnGstTotal(),
      totalQuantity: returnTotalQuantity(),
      userId: null,
    };
    const docRef = await addDoc(shopOrdersRef, orderData);
    const orderId = docRef.id;
    console.log(orderData);
    await updateDoc(docRef, { orderId });
    const receipt1 = (
      // 32 bluetooch printer
      <Printer type="epson" width={32} debug={true}>
        <Text align="center" size={{ width: 2, height: 2 }}>
          {user?.name}
        </Text>
        <Text align="center">{user?.address}</Text>
        <Text align="center">Ph:{user?.phoneNumber}</Text>
        <Line character="-" />
        <Text>Order ID: {orderId}</Text>
        <Text>GST No:{user?.gst}</Text>
        <Text>
          Order Time: {format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS")}
        </Text>
        <Row left={`Payment: ${cashMode}`} right="Token: 1" />
        <Line style={{ border: 1 }} />
        {cart.map((item, index) => {
          return (
            <>
              <Br />
              <Text size={{ width: 1, height: 1 }}>
                {index + 1}.{item.name}({item.priceDetails.priceType})
              </Text>
              <Row
                left={`Price ${item.priceDetails.price.toFixed(2)}  Qty ${
                  item.quantity
                }`}
                right={
                  <Text>
                    {(item.priceDetails.price * item.quantity).toFixed(2)}
                  </Text>
                }
              />
            </>
          );
        })}
        <Line character="=" />
        <Row
          left="Total Price"
          right={<Text>{calculateTotalCartCost().toFixed(2)}</Text>}
        />
        <Row
          left={<Text>GST ({user?.gstPercentage}%)</Text>}
          right={
            <Text>
              {((calculateTotalCartCost() * user?.gstPercentage) / 100).toFixed(
                2
              )}
            </Text>
          }
        />
        <Line character="=" />
        <Row
          left="Total Amount"
          right={
            <Text>
              {(
                calculateTotalCartCost() -
                (calculateTotalCartCost() * user?.gstPercentage) / 100
              ).toFixed(2)}
            </Text>
          }
        />
        <Line character="=" />
        <Br />
        <Text align="center">Thank you! Visit Again</Text>
        <Br />
        <Br />
        <Br />
      </Printer>
    );
    const receipt2 = (
      // 42 3 inch bluetooch printer
      <Printer type="epson" width={42} debug={true}>
        <Text align="center" size={{ width: 2, height: 2 }}>
          {user?.name}
        </Text>
        <Text align="center">{user?.address}</Text>
        <Text align="center">Ph:{user?.phoneNumber}</Text>
        <Line character="=" />
        <Text>Order ID: {orderId}</Text>
        <Text>GST No:{user?.gst}</Text>
        <Text>
          Order Time: {format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS")}
        </Text>
        <Row left={`Payment: ${cashMode}`} right="Token: 1" />
        <Line character="=" />
        {cart.map((item, index) => {
          return (
            <>
              <Br />
              <Text size={{ width: 1, height: 1 }}>
                {index + 1}.{item.itemName}({item.priceDetails.priceType})
              </Text>
              <Row
                left={`Price ${item.priceDetails.price}  Qty ${item.quantity}`}
                right={<Text>{item.priceDetails.price * item.quantity}</Text>}
              />
            </>
          );
        })}
        <Line character="=" />
        <Row
          left="Total Price"
          right={<Text>{calculateTotalCartCost()}</Text>}
        />
        <Row
          left={<Text>GST ({user?.gstPercentage}%)</Text>}
          right={
            <Text>
              {(calculateTotalCartCost() * user?.gstPercentage) / 100}
            </Text>
          }
        />
        <Line character="=" />
        <Row
          left="Total Amount"
          right={
            <Text>
              {calculateTotalCartCost() -
                (calculateTotalCartCost() * user?.gstPercentage) / 100}
            </Text>
          }
        />
        <Line character="=" />
        <Br />
        <Text align="center">Thank you! Visit Again</Text>
        <Br />
        <Br />
        <Cut />
      </Printer>
    );
    dispatch(setCartEmpty());
    // if (!bluetoothCharacteristic && !usbDevice) {
    //   setMessage("Please connect to a printer first.");
    //   return;
    // }
    try {
      if (bluetoothCharacteristic) {
        // const writer = bluetoothCharacteristic.writable?.getWriter();
        console.log("Writing to Bluetooth characteristic...");
        const p = await render(receipt1);
        // await writer.write(p);
        // writer.releaseLock();
        await bluetoothWriteDataInChunks(p);
      } else if (usbDevice) {
        console.log("Came to usb");
        const p = await render(receipt2);
        // await usbDevice.transferOut(1, receipt1);
        await usbWriteDataInChunks(p);
        console.log("Sending data to USB device...");
        // await usbDevice.transferOut(1, p);
      }
      setMessage("Thank you message printed successfully");
    } catch (error) {
      console.error("Error printing thank you message:", error);
      setMessage("Failed to print thank you message");
    }
  };

  const handleUSB = async () => {
    try {
      // Request a USB device with no specific filters
      const usbDevice = await navigator.usb.requestDevice({ filters: [] });
      // Open the selected device
      await usbDevice.open();
      await usbDevice.selectConfiguration(
        usbDevice.configurations[0].configurationValue
      );
      // Claim the first interface
      await usbDevice.claimInterface(
        usbDevice.configuration.interfaces[0].interfaceNumber
      );
      dispatch(setUsbDeviceR(usbDevice));
      setUsbDevice(usbDevice);
      handleConnectModal();
    } catch (error) {
      console.error("Error selecting USB device:", error);
    }
  };

  useEffect(() => {
    getItems();
    const storedToken = localStorage.getItem("tokenNumber");
    if (storedToken) {
      setTokenNumber(parseInt(storedToken, 10));
    }
    const intervalId = setInterval(() => {
      const now = new Date();
      if (
        now.getHours() === 0 &&
        now.getMinutes() === 0 &&
        now.getSeconds() === 0
      ) {
        setTokenNumber(1);
        localStorage.setItem("tokenNumber", "1");
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    border: "none",
    "@media (max-width: 600px)": {
      width: "95%",
      height: "auto",
    },
  };
  const handleOpen = () => setOpenDrawer(true);
  const handleClose = () => setOpenDrawer(false);
  const returnGstTotal = () => {
    return (
      calculateTotalCartCost() *
      (user?.gstPercentage ? user?.gstPercentage / 100 : 0)
    );
  };

  //closing connection modal
  const handleConnectModal = () => {
    dispatch(setOpenConnecteModal(false));
  };
  // connecting to bluetooth
  const handleBluetooth = async () => {
    try {
      const device = await navigator.bluetooth.requestDevice({
        acceptAllDevices: true,
        optionalServices: ["000018f0-0000-1000-8000-00805f9b34fb"],
      });
      dispatch(setDevices(device));

      const server = await device.gatt.connect();
      const service = await server.getPrimaryService(
        "000018f0-0000-1000-8000-00805f9b34fb"
      );
      const characteristic = await service.getCharacteristic(
        "00002af1-0000-1000-8000-00805f9b34fb"
      );
      console.log(server, service, characteristic);
      dispatch(() => setBluetoothCharacteristic(characteristic));
      dispatch(setBluetoothDevice(characteristic));

      setBluetoothCharacteristic(characteristic);
      setOpenBluetooth(false);
      handleConnectModal();
    } catch (error) {
      console.error("Error:", error);
      setMessage("Failed to find or connect to a Bluetooth device.");
    }
  };

  return (
    <div>
      <Header
        setBluetoothCharacteristic={setBluetoothCharacteristic}
        setUsbDevice={setUsbDevice}
      />
      {/* <Modal
        open={openConnectModal}
        onClose={handleConnectModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex items-center justify-center">
            <Button onClick={handleUSB}>USB</Button>
            <Button onClick={handleBluetooth}>Bluetooth</Button>
            <Button>Serial</Button>
          </div>
        </Box>
      </Modal> */}
      <div className="flex">
        <SideBar />
        <div className="w-[13%] border-r-[1px] border-solid border-[#EAECF0] h-[calc(100vh-64px)]">
          <div className="w-[95%] m-auto pt-[24px] flex flex-col gap-[8px]">
            {category.map((e, i) => (
              <p
                className={`bg-[#EAECF0] py-[10px] pl-[12px] rounded-[6px] text-[#1D192B] font-semibold text-[14px] cursor-pointer ${
                  activeFilterId === i
                    ? "!bg-[#674FA3] text-white"
                    : "border-black"
                }`}
                key={i}
                onClick={() => {
                  if (activeFilterId === i) {
                    setActiveFilterId(null);
                    filterItems(e.toLowerCase());
                  } else {
                    setActiveFilterId(i);
                    filterItems(e.toLowerCase());
                  }
                }}
              >
                {e}
              </p>
            ))}
          </div>
        </div>
        <div className="w-[47%] border-r-[1px] border-solid border-[#EAECF0] h-[calc(100vh-64px)] overflow-scroll">
          <div className="sticky top-0 bg-white pt-[24px]">
            <div className="w-[95%] m-auto relative">
              <input
                className="bg-[#EAECF0] py-[12px] pl-[56px] w-[100%] rounded-[28px] focus:outline-none text-[#1D192B] placeholder:text-[#1D192B]"
                placeholder="Search Item Name/Code"
              />
              <IoMdSearch className="absolute top-[15px] left-[26px] text-[20px]" />
            </div>
          </div>
          {filteredMenu.length > 0 ? (
            <div className="pt-[24px] w-[95%] m-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-[20px]">
              {filteredMenu.map((e, i) => (
                <div
                  key={i}
                  className={`w-[145px] bg-[#F5EFF9] rounded-[8px] pb-[14px] cursor-pointer  ${
                    returnSelected(e.id) ? "border-2 border-green-400" : ""
                  }`}
                  onClick={() => handleOpenModal(e)}
                  // onDoubleClick={() => handleOpenModal(e)}
                >
                  <img
                    src={e.imageUrl}
                    alt={e.id}
                    className="h-[96px] w-[100%] object-cover rounded-[8px]"
                  />
                  <div className="w-[90%] flex items-center justify-between m-auto mt-[10px]">
                    <p className="text-[12px] text-[#1D192B] w-[116px]">
                      {e.name}
                    </p>
                    {returnSelected(e.id) && (
                      <div className="p-1 bg-green-500 text-white text-[10px] rounded-full">
                        <MdOutlineDone className="text-[15px]" />
                      </div>
                    )}
                    {/* <button className="h-[28px] bg-[#CFBCFF] border-[1px] border-solid border-[#674FA3] rounded-[4px] text-[12px] px-[7px]">
                    + Add
                  </button> */}
                  </div>
                </div>
              ))}
              <Modal
                open={openDrawer}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="border-none focus:outline-none"
              >
                <Box
                  sx={style}
                  className="rounded-lg border-none focus:outline-none overflow-scroll"
                >
                  <div>
                    <div className="bg-gray-300 rounded-md">
                      <div className="w-[95%] flex items-center justify-between m-auto py-3">
                        <img
                          src={item?.imageUrl}
                          alt=""
                          className="w-[40px] h-[40px] object-cover rounded-full"
                        />
                        <p className="font-semibold">{item?.name}</p>
                        <IoCloseCircleOutline
                          className="cursor-pointer"
                          size={25}
                          onClick={() => setOpenDrawer(false)}
                        />
                      </div>
                    </div>
                    <div>
                      {item?.priceDetails?.map((e, i) => (
                        <>
                          <div
                            className="w-[90%] flex justify-between m-auto items-center py-4 border-b-[1px] border-b-solid"
                            key={i}
                          >
                            <div className="flex gap-4 items-center">
                              <p
                                className={`rounded-full flex items-center justify-center h-[40px] w-[40px] ${
                                  returnQuantity(e.id) > 0
                                    ? "bg-[#674FA3] text-white"
                                    : "bg-[#CFBCFF]"
                                }`}
                              >
                                {returnQuantity(e.id)}
                              </p>
                              <div>
                                <p className="font-semibold">{e.priceType}</p>
                                <p className="font-semibold text-[#578db6]">
                                  {e.price} Rs/-
                                </p>
                              </div>
                            </div>
                            <div className="flex gap-4 items-center">
                              <LuMinusCircle
                                size={27}
                                color="red"
                                className="cursor-pointer"
                                onClick={() => removeFromCartHandler(item, e)}
                              />
                              <IoMdAddCircleOutline
                                size={30}
                                color="green"
                                className="cursor-pointer"
                                onClick={() => addToCartHandler(item, e)}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <div className="mt-[20vw] flex flex-col items-center justify-center">
                <p className="font-bold text-[20px]">No Menu items Added</p>
                <Link to="/myshop" className="m-auto">
                  <Button
                    variant="outlined"
                    className="!border-[#674fa3] !text-[#674fa3] !font-semibold !mt-2"
                  >
                    Add
                  </Button>
                </Link>
              </div>
            </div>
          )}
        </div>
        <Cart width={"35%"} isHome={true} />
        {/* <div className="w-[35%] overflow-scroll h-[calc(100vh-64px)]">
          <div className="w-[95%] m-auto flex justify-between pt-[29px] sticky top-0 bg-white">
            <p className="font-bold text-[24px] leading-[28.13px] text-[#1D192B]">
              Cart({cart.length})
            </p>
            <p
              className="font-semibold text-[14px] leading-[20px] text-[#EC443B] flex items-center gap-[4px] cursor-pointer"
              onClick={() => dispatch(setCartEmpty())}
            >
              <MdDeleteForever className="text-[16px]" />
              Clear Cart
            </p>
          </div>
          <div className="overflow-scroll h-[315px]">
            {cart.map((e) => (
              <div className="mt-[12px] w-[93%] m-auto">
                <div className="flex justify-between items-center">
                  <div className="flex gap-[8px]">
                    <img
                      src={e.imageUrl}
                      alt="img"
                      className="h-[60px] w-[60px] object-cover rounded-[8px] shadow-md"
                    />
                    <div className="w-[224px]">
                      <p className="text-[15px] font-semibold overflow-auto text-[#1D192B] leading-[17.8px]">
                        {e.name}
                      </p>
                      <p className="text-[#717171] text-[13px] font-semibold pt-[5px]">
                        {e.priceDetails.priceType}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center w-[68px]">
                    <button
                      className="w-[24px] h-[32px] bg-[#674FA3] rounded-tl-[4px] rounded-bl-[4px] font-bold text-white text-[20px]"
                      onClick={() =>
                        dispatch(decreasQuantity({ id: e.priceDetails.id }))
                      }
                    >
                      -
                    </button>
                    <p className="w-[20px] h-[32px] bg-[#CFBCFF] font-bold text-black text-[14px] flex items-center justify-center">
                      {e.quantity}
                    </p>
                    <button
                      className="w-[24px] h-[32px] bg-[#674FA3] rounded-br-[4px] rounded-tr-[4px] font-bold text-white text-[20px]"
                      onClick={() =>
                        dispatch(updateQuanity({ id: e.priceDetails.id }))
                      }
                    >
                      +
                    </button>
                  </div>
                  <div>
                    <p className="font-bold text-[16px] leading-[18.75px] text-[#1D192B] w-[51px]">
                      <span>&#8377;</span>
                      {calculateQuantityPrize(
                        e.quantity,
                        e.priceDetails.price
                      ).toFixed(2)}
                    </p>
                  </div>
                </div>
                <div className="w-[88%] ml-auto border-b border-b-[#EAECF0] pt-[12px]"></div>
              </div>
            ))}
          </div>

          <div className="w-[100%] top-[100%] sticky z-50 bg-white">
            <div className="border-[1px] rounded-[8px] border-[#EAECF0] bg-[#FAFAFA] w-[95%] m-auto">
              <div className="flex justify-between w-[95%] m-auto pt-[12px]">
                <p className="font-semibold text-[14px] leading-[16.41px] text-[#717171]">
                  Price:
                </p>
                <p className="text-[14px] leading-[16.41px] text-[#1D192B]">
                  <span>&#8377;</span>
                  {calculateTotalCartCost()?.toFixed(2)}/-
                </p>
              </div>
              <div className="flex justify-between w-[95%] m-auto pt-[12px] mt-auto">
                <p className="font-semibold text-[14px] leading-[16.41px] text-[#717171]">
                  GST ({user?.gstPercentage}%):
                </p>
                <p className="text-[14px] leading-[16.41px] text-[#1D192B]">
                  <span>&#8377;</span>
                  {returnGstTotal()?.toFixed(2)}/-
                </p>
              </div>
              <div className="w-[100%] ml-auto border-b border-b-[#EAECF0] pt-[12px]"></div>
              <div className="flex justify-between items-center w-[95%] m-auto pt-[12px] pb-[12px]">
                <p className="font-semibold text-[14px] leading-[16.41px] text-[#1D192B]">
                  Add discount:
                </p>
                {addDiscount ? (
                  <div>
                    <Input
                      placeholder="Discount.."
                      onChange={(e) => {
                        // const inputValue = e.target.value;
                        // const discountValue = parseInt(inputValue) || 0;
                        setDiscount(parseFloat(e.target.value));
                      }}
                      value={discount}
                      type="number"
                    />
                  </div>
                ) : null}
                {!addDiscount ? (
                  <Button
                    size="small"
                    onClick={() => setAddDiscount(!addDiscount)}
                  >
                    +Add
                  </Button>
                ) : (
                  <Button
                    size="small"
                    onClick={() => {
                      setAddDiscount(false);
                      setDiscount(0);
                    }}
                  >
                    -Remove
                  </Button>
                )}
              </div>
              <div className="flex justify-between w-[95%] m-auto pt-[12px] pb-[12px]">
                <p className="font-semibold text-[14px] leading-[16.41px] text-[#1D192B]">
                  Final Total:
                </p>
                <p className="font-semibold text-[14px] leading-[16.41px] text-[#1D192B]">
                  <span>&#8377;</span>
                  {addDiscount
                    ? disCountCalculateTotalCartCost()?.toFixed(2)
                    : removeDisCountCalculateTotalCartCost().toFixed(2)}
                  /-
                </p>
              </div>
            </div>
            <p className="font-semibold text-[14px] leading-[16.41px] pl-[13px] mt-2">
              Payment method
            </p>
            <div className="flex gap-[8px] pt-[12px] pl-[13px]">
              <p
                className={`cursor-pointer border-[1px] px-[16px] py-[6px] border-solid border-[#717171] text-[14px] text-[#1D192B] rounded-[6px] ${
                  cashMode === "CASH" ? "bg-[#674fa3] text-white" : ""
                }`}
                onClick={() => setCashMode("CASH")}
              >
                Cash
              </p>
              <p
                className={`cursor-pointer border-[1px] px-[16px] py-[6px] border-solid border-[#717171] text-[14px] text-[#1D192B] rounded-[6px] ${
                  cashMode === "UPI" ? "bg-[#674fa3] text-white" : ""
                }`}
                onClick={() => setCashMode("UPI")}
              >
                UPI
              </p>
              <p
                className={`cursor-pointer border-[1px] px-[16px] py-[6px] border-solid border-[#717171] text-[14px] text-[#1D192B] rounded-[6px] ${
                  cashMode === "CARD" ? "bg-[#674fa3] text-white" : ""
                }`}
                onClick={() => setCashMode("CARD")}
              >
                Credit/Debit Card
              </p>
              <p
                className={`cursor-pointer border-[1px] px-[16px] py-[6px] border-solid border-[#717171] text-[14px] text-[#1D192B] rounded-[6px] ${
                  cashMode === "NET BANKING" ? "bg-[#674fa3] text-white" : ""
                }`}
                onClick={() => setCashMode("NET BANKING")}
              >
                Net Banking
              </p>
            </div>
            <div className="mt-[15px]">
              <div className="py-[16px] flex gap-[12px] text-[16px] flex-wrap justify-center">
                <button
                  className="bg-[#674FA3] flex gap-[8px] items-center rounded-[60px] leading-[20px] h-[38px] px-[28.5px] text-white"
                  onClick={() => {
                    navigation("/tables");
                  }}
                >
                  <MdTableBar className="text-[16px]" /> Add To Table
                </button>
                <button className="bg-[#674FA3] flex gap-[8px] items-center rounded-[60px] leading-[20px] h-[38px] px-[28.5px] text-white">
                  <FaReceipt className="text-[16px]" /> Save
                </button>

                <button
                  className="bg-[#4CAA54] flex items-center gap-[8px] rounded-[60px] leading-[20px] h-[38px] px-[28.5px] text-white"
                  onClick={handlePrintThankYou}
                >
                  <BiPrinter className="text-[16px]" /> Save + Print
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default HomePage;
