import React from "react";

const RefundPolicy = () => {
  return (
    <div>
      <h1 className="text-center text-[40px] font-bold">
        Cancellation/Refund Policy
      </h1>
      <br />
      <span className="font-bold">1. Introduction</span>
      <br />
      At BillKarlo, we value customer satisfaction and aim to provide a seamless
      experience with our services. This Cancellation/Refund Policy outlines the
      procedures and conditions under which cancellations and refunds may be
      requested and processed.
      <br />
      <span className="font-bold">2. Subscription Cancellations</span>
      <br />
      2.1 Cancellation Request: You can cancel your subscription at any time
      through your account settings or by contacting our support team.
      <br />
      2.2 Effective Date: The cancellation will take effect at the end of your
      current billing cycle. You will continue to have access to the services
      until the end of the current subscription period.
      <br />
      2.3 No Prorated Refunds: We do not offer prorated refunds for partially
      used subscription periods. Once a subscription period begins, it must be
      completed before the cancellation takes effect.
      <br />
      <span className="font-bold">3. Free Trial Cancellations</span>
      <br />
      3.1 Free Trial Period: New users are entitled to a free trial period as
      specified on our website
      <br />
      3.2 Cancellation During Free Trial: You can cancel your subscription at
      any time during the free trial period without incurring any charges. If
      you do not cancel before the trial period ends, you will be automatically
      charged according to the subscription plan you selected.
      <br />
      <br />
      <span className="font-bold">4. . Refund Policy</span>
      <br />
      4.1 No Refunds Policy: Subscription fees are generally non-refundable. We
      do not offer refunds for any unused portion of your subscription period.
      <br />
      4.2 Exceptional Cases: Refund requests will be considered on a
      case-by-case basis in exceptional circumstances, such as: • Service
      interruptions due to technical issues on our end • Duplicate billing •
      Billing errors
      <br />
      4.3 Refund Process: To request a refund, contact our support team with
      your account details and the reason for the refund request. We will review
      your request and notify you of the decision within 5-7 business days
      <br />
      <span className="font-bold">
        5. Refunds for Defective or Inaccurate Charges
      </span>{" "}
      <br />
      5.1 Billing Errors: If you believe there has been an error in billing,
      contact us immediately. We will investigate and, if an error is found,
      issue a refund for the incorrect charge.
      <br />
      5.2 Duplicate Charges: In the event of duplicate charges, cont
      <br />
      <span className="font-bold">6. Processing Refunds</span> <br />
      6.1 Refund Method: Refunds will be processed using the original payment
      method. If the original payment method is no longer available, please
      contact our support team for alternative arrangements.
      <br />
      6.2 Refund Timeframe: Once approved, refunds will be processed within 7-10
      business days. The time it takes for the refund to reflect in your account
      may vary depending on your payment provider.
      <br />
      <span className="font-bold">9. Contact Information</span> <br />
      If you have any questions or concerns about our Cancellation/Refund
      Policy, please contact us at: <br />
      <span className="font-bold">
        <br />
        Bill Karlo
      </span>{" "}
      <br />
      billkarlo.builders@gmail.com <br />
      +91 9381403246
      <br />
    </div>
  );
};

export default RefundPolicy;
