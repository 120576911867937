import React from "react";
import { FaReceipt } from "react-icons/fa";
import { MdHome, MdReceipt } from "react-icons/md";
import { TbFileAnalytics } from "react-icons/tb";
import { Link, useLocation } from "react-router-dom";
import { TiPrinter } from "react-icons/ti";
import { MdTableBar } from "react-icons/md";
import { IoQrCodeSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { setCartEmpty } from "../redux/slices/cartSlice";
const SideBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  return (
    <div className="w-[5%] border-r-[1px] border-solid border-[#EAECF0] h-[calc(100vh-64px)]">
      <div className="w-[56px] m-auto flex flex-col items-center gap-[24px] pt-[24px]">
        <Link
          to="/home"
          onClick={() => {
            dispatch(setCartEmpty());
          }}
        >
          <div
            className={`w-[52px] rounded-[66px] h-[32px] pt-[6px] pb-[6px] ${
              location.pathname === "/home" ? "bg-[#ECE4FF]" : "bg-white"
            }`}
          >
            <TiPrinter
              className={`m-auto text-[16px] ${
                location.pathname === "/home"
                  ? "text-[#674FA3]"
                  : "text-[#717171]"
              }`}
            />
          </div>
          <p
            className={`font-semibold leading-[16.41px] text-center text-[14px] ${
              location.pathname === "/home"
                ? "text-[#674FA3]"
                : "text-[#717171]"
            }`}
          >
            Billing
          </p>
        </Link>
        <Link to="/myshop">
          <div
            className={`w-[52px] rounded-[66px] h-[32px] pt-[6px] pb-[6px] ${
              location.pathname === "/myshop" ? "bg-[#ECE4FF]" : "bg-white"
            }`}
          >
            <MdHome
              className={`m-auto text-[16px] ${
                location.pathname === "/myshop"
                  ? "text-[#674FA3]"
                  : "text-[#717171]"
              }`}
            />
          </div>
          <p
            className={`font-semibold leading-[16.41px] text-center text-[14px] ${
              location.pathname === "/myshop"
                ? "text-[#674FA3]"
                : "text-[#717171]"
            }`}
          >
            My Shop
          </p>
        </Link>
        <Link to="/orders">
          <div
            className={`w-[52px] rounded-[66px] h-[32px] pt-[6px] pb-[6px] ${
              location.pathname === "/orders" ? "bg-[#ECE4FF]" : "bg-white"
            }`}
          >
            <MdReceipt
              className={`m-auto text-[16px] ${
                location.pathname === "/orders"
                  ? "text-[#674FA3]"
                  : "text-[#717171]"
              }`}
            />
          </div>
          <p
            className={`font-semibold leading-[16.41px] text-center text-[14px] ${
              location.pathname === "/orders"
                ? "text-[#674FA3]"
                : "text-[#717171]"
            }`}
          >
            Orders
          </p>
        </Link>
        <Link
          to="/tables"
          onClick={() => {
            dispatch(setCartEmpty());
          }}
        >
          <div
            className={`w-[52px] rounded-[66px] h-[32px] pt-[6px] pb-[6px] ${
              location.pathname === "/tables" ? "bg-[#ECE4FF]" : "bg-white"
            }`}
          >
            <MdTableBar
              className={`m-auto text-[16px] ${
                location.pathname === "/tables"
                  ? "text-[#674FA3]"
                  : "text-[#717171]"
              }`}
            />
          </div>
          <p
            className={`font-semibold leading-[16.41px] text-center text-[14px] ${
              location.pathname === "/tables"
                ? "text-[#674FA3]"
                : "text-[#717171]"
            }`}
          >
            Tables
          </p>
        </Link>
        {/* <Link to="/qr">
          <div
            className={`w-[52px] rounded-[66px] h-[32px] pt-[6px] pb-[6px] ${
              location.pathname === "/qr" ? "bg-[#ECE4FF]" : "bg-white"
            }`}
          >
            <IoQrCodeSharp
              className={`m-auto text-[16px] ${
                location.pathname === "/qr"
                  ? "text-[#674FA3]"
                  : "text-[#717171]"
              }`}
            />
          </div>
          <p
            className={`font-semibold leading-[16.41px] text-center text-[14px] ${
              location.pathname === "/qr" ? "text-[#674FA3]" : "text-[#717171]"
            }`}
          >
            Shop QR
          </p>
        </Link> */}
        <Link to="/reports">
          <div
            className={`w-[52px] rounded-[66px] h-[32px] pt-[6px] pb-[6px] ${
              location.pathname === "/reports" ? "bg-[#ECE4FF]" : "bg-white"
            }`}
          >
            <TbFileAnalytics
              className={`m-auto text-[16px] ${
                location.pathname === "/reports"
                  ? "text-[#674FA3]"
                  : "text-[#717171]"
              }`}
            />
          </div>
          <p
            className={`font-semibold leading-[16.41px] text-center text-[14px] ${
              location.pathname === "/reports"
                ? "text-[#674FA3]"
                : "text-[#717171]"
            }`}
          >
            Reports
          </p>
        </Link>
      </div>
    </div>
  );
};

export default SideBar;
