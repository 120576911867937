import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import {
  Avatar,
  Button,
  Input,
  InputLabel,
  Skeleton,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getAuth } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

const ProfilePage = () => {
  const [userLoading, setUserLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const uid = getAuth().currentUser.uid;
  const getUser = async () => {
    setUserLoading(true);
    const getUser = doc(db, "users", uid);
    const user = await getDoc(getUser);
    if (user.exists()) {
      console.log(user.data());
      setUserDetails(user.data());
    }
    setUserLoading(false);
  };
  useEffect(() => {
    getUser();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]:
        name === "gstPercentage"
          ? value === ""
            ? ""
            : parseFloat(value)
          : value,
    }));
  };
  const handleSave = async (e) => {
    // e.preventDefault();
    // setSaving(true);
    try {
      const userDoc = doc(db, "users", uid);
      await updateDoc(userDoc, userDetails);
      console.log("User details updated successfully");
    } catch (error) {
      console.error("Error updating user details: ", error);
    } finally {
      //   setSaving(false);
    }
  };
  return (
    <div>
      <Header />
      <div className="flex">
        <SideBar />
        <div className="border-[0.5px] border-solid rounded-md self-center m-auto shadow-md ">
          {userLoading ? (
            <div className="w-[400px] flex gap-4 flex-col py-[40px] items-center">
              <Skeleton
                variant="circular"
                width={70}
                height={70}
                className="m-auto"
              />
              <Skeleton variant="rectangular" width={"90%"} height={30} />
              <Skeleton variant="rounded" width={"90%"} height={30} />
              <Skeleton variant="rectangular" width={"90%"} height={30} />
              <Skeleton variant="rounded" width={"90%"} height={30} />
            </div>
          ) : (
            <div className="py-[40px]">
              <div className="w-[90%] m-auto flex flex-col gap-3">
                <Avatar
                  src={userDetails.profilePic}
                  sx={{ width: 70, height: 70, margin: "auto" }}
                />
                <div className="w-[100%]">
                  <InputLabel size="normal">Phone number</InputLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="phoneNumber"
                    value={userDetails.phoneNumber}
                    onChange={handleChange}
                    className="w-[100%]"
                  />
                </div>
                <div className="w-[100%]">
                  <InputLabel size="normal">Name</InputLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="name"
                    value={userDetails.name}
                    onChange={handleChange}
                    className="w-[100%]"
                  />
                </div>
                <div className="w-[100%]">
                  <InputLabel size="normal">Email</InputLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="email"
                    value={userDetails.email}
                    onChange={handleChange}
                    className="w-[100%]"
                  />
                </div>
                <div className="flex gap-[10px]">
                  <div className="w-[70%]">
                    <InputLabel size="normal">GST</InputLabel>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="gst"
                      value={userDetails.gst}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-[30%]">
                    <InputLabel size="normal">Percentage</InputLabel>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="gstPercentage"
                      value={userDetails.gstPercentage}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="w-[100%]">
                  <InputLabel size="normal">Address</InputLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="address"
                    value={userDetails.address}
                    onChange={handleChange}
                    className="w-[100%]"
                    rows={4}
                    multiline
                  />
                </div>
                <Button
                  onClick={handleSave}
                  variant="contained"
                  className="bg-[#674FA3]"
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
