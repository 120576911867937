import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mainId: "",
  user: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setId: (state, action) => {
      state.mainId = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setId, setUser } = userSlice.actions;
export default userSlice.reducer;
