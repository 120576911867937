import React from "react";

const TermsAndCondtions = () => {
  return (
    <div>
      <h1 className="text-center text-[40px] font-bold">
        Terms and Conditions
      </h1>
      <br />
      <span className="font-bold">1. Introduction</span>
      <br />
      Welcome to BillKarlo, a comprehensive software solution designed to
      streamline operations for small businesses. By accessing or using our
      website and services, you agree to comply with and be bound by these terms
      and conditions. If you do not agree to these terms, please do not use our
      services.
      <br />
      <span className="font-bold">2. Services Provided</span>
      <br />
      BillKarlo offers features including billing and payments, order
      management, inventory tracking, customer relationship management (CRM),
      reporting and analytics, QR code ordering, and admin portals for franchise
      management.
      <br />
      <span className="font-bold">3. User Accounts</span>
      <br />
      3.1 Account Creation: To use our services, you must create an account by
      providing accurate and complete information. You are responsible for
      maintaining the confidentiality of your account credentials.
      <br />
      3.2 Account Responsibilities: You are responsible for all activities that
      occur under your account. If you suspect any unauthorized use of your
      account, you must notify us immediately.
      <br />
      <span className="font-bold">4. Payments and Billing</span>
      <br />
      4.1 Subscription Fees: Our services are available on a subscription basis
      following a free trial period. Subscription fees are outlined on our
      pricing page and are subject to change.
      <br />
      4.2 Payment Methods: Payments are processed through Razorpay. By using our
      services, you agree to Razorpay's terms and conditions.
      <br />
      4.3 Billing Cycle: Subscription fees are billed in advance on a monthly or
      annual basis as per the chosen plan.
      <br />
      4.4 Late Payments: Failure to pay subscription fees on time may result in
      the suspension or termination of your account. <br />
      <span className="font-bold">5. Free Trial</span> <br />
      5.1 Duration: New users are entitled to a free trial period as specified
      on our website. <br />
      5.2 Termination of Free Trial: We reserve the right to terminate or modify
      the free trial period without prior notice. <br />
      <span className="font-bold">6. Refunds</span> <br />
      6.1 No Refunds: Subscription fees are non-refundable except as required by
      law or at our sole discretion. <br />
      6.2 Exceptional Cases: Refund requests will be considered on a
      case-by-case basis and must be submitted to our support team. <br />
      <span className="font-bold">7. Use of the Service</span> <br />
      7.1 Compliance: You agree to use our services in compliance with all
      applicable laws and regulations. <br />
      7.2 Prohibited Activities: You may not use our services for any illegal or
      unauthorized purpose, including but not limited to spamming, hacking, or
      distributing malicious software. <br />
      <span className="font-bold">8. Data Privacy and Security</span>
      <br />
      8.1 Data Collection: We collect and use personal data in accordance with
      our Privacy Policy.
      <br />
      8.2 Data Security: We implement robust security measures to protect your
      data from unauthorized access, alteration, or disclosure.
      <br />
      8.3 User Responsibilities: You are responsible for maintaining the
      security of your account and ensuring that your data is accurate and
      up-to-date.
      <br />
      <span className="font-bold">9. Intellectual Property</span>
      <br />
      9.1 Ownership: All content and materials on our website, including but not
      limited to text, graphics, logos, and software, are the intellectual
      property of BillKarlo or its licensors.
      <br />
      9.2 Usage Rights: You are granted a limited, non-exclusive,
      non-transferable license to access and use our services for your business
      operations.
      <br />
      <span className="font-bold">10. Limitation of Liability</span>
      <br />
      10.1 Indirect Damages: To the fullest extent permitted by law, BillKarlo
      shall not be liable for any indirect, incidental, special, consequential,
      or punitive damages, or any loss of profits or revenues.
      <br />
      10.2 Direct Damages: Our liability for direct damages arising from your
      use of our services shall be limited to the amount you have paid to us in
      the past six months.
      <br />
      <span className="font-bold">11. Termination</span>
      <br />
      11.1 Termination by You: You may terminate your account at any time by
      contacting our support team.
      <br />
      11.2 Termination by Us: We reserve the right to terminate or suspend your
      access to our services at any time, without notice, for conduct that we
      believe violates these terms or is harmful to other users, us, or third
      parties.
      <br />
      <span className="font-bold">12. Changes to Terms and Conditions</span>
      <br />
      12.1 Modifications: We may revise these terms from time to time. The most
      current version will always be posted on our website.
      <br />
      12.2 Acceptance of Changes: By continuing to access or use our services
      after revisions become effective, you agree to be bound by the updated
      terms
      <br />
      <span className="font-bold">13. Governing Law</span> <br />
      These Terms and Conditions shall be governed by and construed in
      accordance with the laws of India, without regard to its conflict of law
      principles. <br />
      <span className="font-bold">14. Dispute Resolution</span> <br />
      14.1 Negotiation: In the event of any dispute, you agree to first attempt
      to resolve the issue by contacting us directly. <br />
      14.2 Arbitration: If the dispute cannot be resolved through negotiation,
      it shall be settled by binding arbitration in accordance with the Indian
      Arbitration and Conciliation Act.
      <span className="font-bold">15. Contact Information</span> <br />
      For any questions about these Terms and Conditions, please contact us at:
      BillKarlo <br />
      <span className="font-bold">
        <br />
        Bill Karlo
      </span>{" "}
      <br />
      billkarlo.builders@gmail.com <br />
      +91 9381403246
      <br />
      <span className="font-bold">16. Acknowledgment</span>
      <br />
      <br />
      By using our services, you acknowledge that you have read, understood, and
      agree to be bound by these Terms and Conditions.
      <br />
    </div>
  );
};

export default TermsAndCondtions;
