import logo from "./logo.svg";
import "./App.css";
import HomePage from "./pages/HomePage";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import PrivateRoute from "./protectedRoute/ProtectedRoute";
import MyShopPage from "./pages/MyShopPage";
import AddShopItems from "./pages/AddShopItems";
import ProfilePage from "./pages/ProfilePage";
import Reports from "./pages/DashboardReports/Reports";
import Dashboard from "./pages/Dashboard";
import Privacy from "./pages/Privacy";
import TermsAndCondtions from "./pages/TermsAndCondtions";
import ShippingPolicy from "./pages/ShippingPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import TableManagement from "./pages/TableManagement";
import ShopOrders from "./pages/ShopOrders";
import ShopQrPage from "./pages/ShopQrPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/termsandconditions" element={<TermsAndCondtions />} />
      <Route path="/shipping" element={<ShippingPolicy />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/myshop"
        element={
          <PrivateRoute>
            <AddShopItems />
          </PrivateRoute>
        }
      />
      <Route
        path="/tables"
        element={
          <PrivateRoute>
            <TableManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/orders"
        element={
          <PrivateRoute>
            <ShopOrders />
          </PrivateRoute>
        }
      />
      <Route
        path="/qr"
        element={
          <PrivateRoute>
            <ShopQrPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/myshop"
        element={
          <PrivateRoute>
            <MyShopPage />
          </PrivateRoute>
        }
      /> */}
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports"
        element={
          <PrivateRoute>
            <Reports />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default App;
