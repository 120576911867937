import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MdDeleteForever,
  MdHome,
  MdOutlineDone,
  MdTableBar,
} from "react-icons/md";
import {
  decreasQuantity,
  setCartEmpty,
  updateQuanity,
} from "../redux/slices/cartSlice";
import { Button, Input } from "@mui/material";
import { FaReceipt } from "react-icons/fa";
import { BiPrinter } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import {
  Br,
  Cut,
  Line,
  Printer,
  render,
  Row,
  Text,
} from "react-thermal-printer";
import { format } from "date-fns";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  runTransaction,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { getAuth } from "firebase/auth";
import { useParams } from "react-router-dom";
const Cart = ({ width, table, data, setTableDrawer, isHome }) => {
  console.log(isHome);
  const auth = getAuth();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const cart = useSelector((state) => state.cart.cart);
  console.log(cart);
  const user = useSelector((state) => state.user.user);
  const bluetooth = useSelector(
    (state) => state.printerDevices.blueToothDevice
  );
  const usbDevice = useSelector((state) => state.printerDevices.usbDevice);
  const [cashMode, setCashMode] = useState("CASH");
  const [addDiscount, setAddDiscount] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [tableItem, setTableItem] = useState([]);
  const [message, setMessage] = useState("");
  const [tokenNumber, setTokenNumber] = useState(1);
  const [saving, setSaving] = useState(false);
  const calculateQuantityPrize = (quantity, price) => {
    return quantity * price;
  };
  const calculateTotalCartCost = () => {
    if (table) {
      return tableItem?.items?.reduce(
        (total, item) =>
          total +
          calculateQuantityPrize(item.quantity, item.priceDetails.price),
        0.0
      );
    } else {
      return cart.reduce(
        (total, item) =>
          total +
          calculateQuantityPrize(item.quantity, item.priceDetails.price),
        0.0
      );
    }
  };
  const disCountCalculateTotalCartCost = () => {
    if (discount > 0) {
      if (table) {
        return (
          tableItem?.items?.reduce(
            (total, item) =>
              total +
              calculateQuantityPrize(item.quantity, item.priceDetails.price),
            0.0
          ) +
          returnGstTotal() -
          discount
        );
      } else {
        return (
          cart.reduce(
            (total, item) =>
              total +
              calculateQuantityPrize(item.quantity, item.priceDetails.price),
            0.0
          ) +
          returnGstTotal() -
          discount
        );
      }
    }
  };
  const returnGstTotal = () => {
    return (
      calculateTotalCartCost() *
      (user?.gstPercentage ? user?.gstPercentage / 100 : 0)
    );
  };
  const removeDisCountCalculateTotalCartCost = () => {
    if (table) {
      return (
        tableItem?.items?.reduce(
          (total, item) =>
            total +
            calculateQuantityPrize(item.quantity, item.priceDetails.price),
          0.0
        ) + returnGstTotal()
      );
    } else {
      return (
        cart.reduce(
          (total, item) =>
            total +
            calculateQuantityPrize(item.quantity, item.priceDetails.price),
          0.0
        ) + returnGstTotal()
      );
    }
  };
  const returnQuantity = (priceId) => {
    for (var element of cart) {
      if (element.priceDetails.id == priceId) {
        return element.quantity;
      }
    }
    return 0;
  };
  const returnTotalQuantity = () => {
    return cart.reduce((total, item) => {
      return total + item.quantity;
    }, 0);
  };
  async function bluetoothWriteDataInChunks(data) {
    const chunkSize = 512;
    let offset = 0;

    while (offset < data.length) {
      const chunk = data.slice(offset, offset + chunkSize);
      await bluetooth.writeValue(chunk);
      offset += chunkSize;
    }
  }
  async function usbWriteDataInChunks(data) {
    const chunkSize = 512;
    let offset = 0;
    while (offset < data.length) {
      const chunk = data.slice(offset, offset + chunkSize);
      await usbDevice.transferOut(1, chunk);
      offset += chunkSize;
    }
  }
  const handlePrintReceipt = async (type) => {
    if (type === "SavePrint") {
      if (!bluetooth && !usbDevice) {
        alert("No connected devices");
        return false;
      }
    }
    setSaving(true);
    const newTokenNumber = tokenNumber + 1;
    setTokenNumber(newTokenNumber);
    localStorage.setItem("tokenNumber", newTokenNumber.toString());
    const shopOrdersRef = collection(doc(db, "shop", user.uid), "shopOrders");
    const orderData = {
      items: cart,
      orderDate: format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
      shopId: user?.uid,
      totalItems: returnQuantity(),
      paymentMode: cashMode,
      shopId: user.uid,
      tableId: null,
      tableNumber: null,
      totalItems: cart?.length,
      grandTotal: addDiscount
        ? disCountCalculateTotalCartCost()
        : removeDisCountCalculateTotalCartCost(),
      totalPrice: calculateTotalCartCost(),
      discountAmount: discount,
      gstAmount: returnGstTotal(),
      totalQuantity: returnTotalQuantity(),
      userId: null,
    };
    const docRef = await addDoc(shopOrdersRef, orderData);
    const orderId = docRef.id;
    await updateDoc(docRef, { orderId });
    const receipt1 = (
      // 32 bluetooch printer
      <Printer type="epson" width={32} debug={true}>
        <Text align="center" size={{ width: 2, height: 2 }}>
          {user?.name}
        </Text>
        <Text align="center">{user?.address}</Text>
        <Text align="center">Ph:{user?.phoneNumber}</Text>
        <Line character="-" />
        <Text>Order ID: {orderId}</Text>
        <Text>GST No:{user?.gst}</Text>
        <Text>Order Time: {format(new Date(), "yyyy-MM-dd HH:mm:ss")}</Text>
        <Row left={`Payment: ${cashMode}`} right={`Token: ${newTokenNumber}`} />
        <Line style={{ border: 1 }} />
        {cart.map((item, index) => {
          return (
            <>
              <Br />
              <Text size={{ width: 1, height: 1 }}>
                {index + 1}.{item.name}({item.priceDetails.priceType})
              </Text>
              <Row
                left={`Price ${item.priceDetails.price.toFixed(2)}  Qty ${
                  item.quantity
                }`}
                right={
                  <Text>
                    {(item.priceDetails.price * item.quantity).toFixed(2)}
                  </Text>
                }
              />
            </>
          );
        })}
        <Line character="=" />
        <Row
          left="Total Price"
          right={<Text>{calculateTotalCartCost().toFixed(2)}</Text>}
        />
        <Row
          left={<Text>GST ({user?.gstPercentage}%)</Text>}
          right={
            <Text>
              {((calculateTotalCartCost() * user?.gstPercentage) / 100).toFixed(
                2
              )}
            </Text>
          }
        />
        <Line character="=" />
        <Row
          left="Total Amount"
          right={
            <Text>
              {(
                calculateTotalCartCost() -
                (calculateTotalCartCost() * user?.gstPercentage) / 100
              ).toFixed(2)}
            </Text>
          }
        />
        <Line character="=" />
        <Br />
        <Text align="center">Thank you! Visit Again</Text>
        <Br />
        <Br />
        <Br />
      </Printer>
    );

    const receipt2 = (
      // 42 3 inch bluetooch printer
      <Printer type="epson" width={42} debug={true}>
        <Text align="center" size={{ width: 2, height: 2 }}>
          {user?.name}
        </Text>
        <Text align="center">{user?.address}</Text>
        <Text align="center">Ph:{user?.phoneNumber}</Text>
        <Line character="=" />
        <Text>Order ID: {orderId}</Text>
        <Text>GST No:{user?.gst}</Text>
        <Text>
          Order Time: {format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS")}
        </Text>
        <Row left={`Payment: ${cashMode}`} right={`Token: ${newTokenNumber}`} />
        <Line character="=" />
        {cart.map((item, index) => {
          return (
            <>
              <Br />
              <Text size={{ width: 1, height: 1 }}>
                {index + 1}.{item.itemName}({item.priceDetails.priceType})
              </Text>
              <Row
                left={`Price ${item.priceDetails.price}  Qty ${item.quantity}`}
                right={<Text>{item.priceDetails.price * item.quantity}</Text>}
              />
            </>
          );
        })}
        <Line character="=" />
        <Row
          left="Total Price"
          right={<Text>{calculateTotalCartCost()}</Text>}
        />
        <Row
          left={<Text>GST ({user?.gstPercentage}%)</Text>}
          right={
            <Text>
              {(calculateTotalCartCost() * user?.gstPercentage) / 100}
            </Text>
          }
        />
        <Line character="=" />
        <Row
          left="Total Amount"
          right={
            <Text>
              {calculateTotalCartCost() -
                (calculateTotalCartCost() * user?.gstPercentage) / 100}
            </Text>
          }
        />
        <Line character="=" />
        <Br />
        <Text align="center">Thank you! Visit Again</Text>
        <Br />
        <Br />
        <Cut />
      </Printer>
    );
    dispatch(setCartEmpty());
    try {
      if (bluetooth) {
        console.log("Writing to Bluetooth characteristic...");
        const p = await render(receipt1);

        await bluetoothWriteDataInChunks(p);
      } else if (usbDevice) {
        console.log("Came to usb");
        const p = await render(receipt2);

        await usbWriteDataInChunks(p);
        console.log("Sending data to USB device...");
      }
      setMessage("Thank you message printed successfully");
      if (table) {
        emptyTableItems(data.id);
        setTableDrawer(false);
      }
      setSaving(false);
    } catch (error) {
      console.error("Error printing thank you message:", error);
      setMessage("Failed to print thank you message");
      setSaving(false);
    }
  };
  useEffect(() => {
    if (!table) return;
    const unsubscribe = getTableItems(data.id);

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);
  useEffect(() => {
    // Load the token number from local storage on component mount
    const storedToken = localStorage.getItem("tokenNumber");
    if (storedToken) {
      setTokenNumber(parseInt(storedToken, 10));
    }

    // Set up an interval to check for midnight reset
    const intervalId = setInterval(() => {
      const now = new Date();
      if (
        now.getHours() === 0 &&
        now.getMinutes() === 0 &&
        now.getSeconds() === 0
      ) {
        setTokenNumber(1);
        localStorage.setItem("tokenNumber", "1");
      }
    }, 1000); // Check every second

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  const getTableItems = (id) => {
    const user = auth.currentUser;
    if (!user) return;

    const shopUid = user.uid;
    const tableRef = doc(db, "shop", shopUid, "tables", id);

    const unsubscribe = onSnapshot(tableRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const tableData = {
          id: docSnapshot.id,
          ...docSnapshot.data(),
        };
        setTableItem(tableData);
      } else {
        console.log("No such document!");
      }
    });

    // Return the unsubscribe function to clean up the listener
    return unsubscribe;
  };

  // for table cart
  const incrementTableItemQuantity = async (itemId, tableId) => {
    const tableDocRef = doc(
      db,
      "shop",
      auth.currentUser.uid,
      "tables",
      tableId
    );
    try {
      await runTransaction(db, async (transaction) => {
        const tableDoc = await transaction.get(tableDocRef);
        if (!tableDoc.exists()) {
          throw new Error("Table does not exist!");
        }
        const data = tableDoc.data();
        const items = data.items || [];

        const updatedItems = items
          .map((item) => {
            if (item.priceDetails.id === itemId) {
              return {
                ...item,
                quantity: item.quantity + 1,
              };
            }
            return item;
          })
          .filter((item) => item !== null);

        transaction.update(tableDocRef, { items: updatedItems });
      });
      getTableItems(data.id);
      console.log("Cart item quantity updated successfully.");
    } catch (error) {
      console.error("Error updating cart item quantity:", error);
    }
  };
  const decrementTableItemQuantity = async (itemId, tableId) => {
    const tableDocRef = doc(
      db,
      "shop",
      auth.currentUser.uid,
      "tables",
      tableId
    );
    try {
      await runTransaction(db, async (transaction) => {
        const tableDoc = await transaction.get(tableDocRef);
        if (!tableDoc.exists()) {
          throw new Error("Table does not exist!");
        }
        const data = tableDoc.data();
        const items = data.items || [];

        const updatedItems = items
          .map((item) => {
            if (item.priceDetails.id === itemId) {
              if (item.quantity - 1 > 0) {
                return {
                  ...item,
                  quantity: item.quantity - 1,
                };
              } else {
                return null;
              }
            }
            return item;
          })
          .filter((item) => item !== null);

        transaction.update(tableDocRef, { items: updatedItems });
      });
      getTableItems(data.id);
      console.log("Cart item quantity updated successfully.");
    } catch (error) {
      console.error("Error updating cart item quantity:", error);
    }
  };
  const emptyTableItems = async (tableId) => {
    const tableDocRef = doc(
      db,
      "shop",
      auth.currentUser.uid,
      "tables",
      tableId
    );

    try {
      await updateDoc(tableDocRef, { items: [] });
      console.log("Items field emptied successfully.");
    } catch (error) {
      console.error("Error emptying items field:", error);
    }
  };
  return (
    <div className={`w-[${width}] overflow-scroll h-[calc(100vh-64px)]`}>
      <div className="w-[95%] m-auto flex justify-between pt-[29px] sticky top-0 bg-white">
        <p className="font-bold text-[24px] leading-[28.13px] text-[#1D192B]">
          Cart({cart.length})
        </p>
        <p
          className="font-semibold text-[14px] leading-[20px] text-[#EC443B] flex items-center gap-[4px] cursor-pointer"
          onClick={() => dispatch(setCartEmpty())}
        >
          <MdDeleteForever className="text-[16px]" />
          Clear Cart
        </p>
      </div>
      <div className="overflow-scroll h-[315px]">
        {table ? (
          <>
            {tableItem?.items?.map((e, i) => (
              <div className="mt-[12px] w-[93%] m-auto" key={i}>
                <div className="flex justify-between items-center">
                  <div className="flex gap-[8px]">
                    <img
                      src={e.imageUrl}
                      alt="img"
                      className="h-[60px] w-[60px] object-cover rounded-[8px] shadow-md"
                    />
                    <div className="w-[224px]">
                      <p className="text-[15px] font-semibold overflow-auto text-[#1D192B] leading-[17.8px]">
                        {e.name}
                      </p>
                      <p className="text-[#717171] text-[13px] font-semibold pt-[5px]">
                        {e.priceDetails.priceType}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center w-[68px]">
                    <button
                      className="w-[24px] h-[32px] bg-[#674FA3] rounded-tl-[4px] rounded-bl-[4px] font-bold text-white text-[20px]"
                      onClick={() => {
                        // dispatch(decreasQuantity({ id: e.priceDetails.id }));
                        decrementTableItemQuantity(e.priceDetails.id, data.id);
                      }}
                    >
                      -
                    </button>
                    <p className="w-[20px] h-[32px] bg-[#CFBCFF] font-bold text-black text-[14px] flex items-center justify-center">
                      {e.quantity}
                    </p>
                    <button
                      className="w-[24px] h-[32px] bg-[#674FA3] rounded-br-[4px] rounded-tr-[4px] font-bold text-white text-[20px]"
                      onClick={() =>
                        // dispatch(updateQuanity({ id: e.priceDetails.id }))
                        incrementTableItemQuantity(e.priceDetails.id, data.id)
                      }
                    >
                      +
                    </button>
                  </div>
                  <div>
                    <p className="font-bold text-[16px] leading-[18.75px] text-[#1D192B] w-[51px]">
                      <span>&#8377;</span>
                      {calculateQuantityPrize(
                        e.quantity,
                        e.priceDetails.price
                      ).toFixed(2)}
                    </p>
                  </div>
                </div>
                <div className="w-[88%] ml-auto border-b border-b-[#EAECF0] pt-[12px]"></div>
              </div>
            ))}
          </>
        ) : (
          <>
            {cart.map((e, i) => (
              <div className="mt-[12px] w-[93%] m-auto" key={i}>
                <div className="flex justify-between items-center">
                  <div className="flex gap-[8px]">
                    <img
                      src={e.imageUrl}
                      alt="img"
                      className="h-[60px] w-[60px] object-cover rounded-[8px] shadow-md"
                    />
                    <div className="w-[224px]">
                      <p className="text-[15px] font-semibold overflow-auto text-[#1D192B] leading-[17.8px]">
                        {e.name}
                      </p>
                      <p className="text-[#717171] text-[13px] font-semibold pt-[5px]">
                        {e.priceDetails.priceType}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center w-[68px]">
                    <button
                      className="w-[24px] h-[32px] bg-[#674FA3] rounded-tl-[4px] rounded-bl-[4px] font-bold text-white text-[20px]"
                      onClick={() =>
                        dispatch(decreasQuantity({ id: e.priceDetails.id }))
                      }
                    >
                      -
                    </button>
                    <p className="w-[20px] h-[32px] bg-[#CFBCFF] font-bold text-black text-[14px] flex items-center justify-center">
                      {e.quantity}
                    </p>
                    <button
                      className="w-[24px] h-[32px] bg-[#674FA3] rounded-br-[4px] rounded-tr-[4px] font-bold text-white text-[20px]"
                      onClick={() =>
                        dispatch(updateQuanity({ id: e.priceDetails.id }))
                      }
                    >
                      +
                    </button>
                  </div>
                  <div>
                    <p className="font-bold text-[16px] leading-[18.75px] text-[#1D192B] w-[51px]">
                      <span>&#8377;</span>
                      {calculateQuantityPrize(
                        e.quantity,
                        e.priceDetails.price
                      ).toFixed(2)}
                    </p>
                  </div>
                </div>
                <div className="w-[88%] ml-auto border-b border-b-[#EAECF0] pt-[12px]"></div>
              </div>
            ))}
          </>
        )}
      </div>

      <div className="w-[100%] top-[100%] sticky z-50 bg-white">
        <div className="border-[1px] rounded-[8px] border-[#EAECF0] bg-[#FAFAFA] w-[95%] m-auto">
          <div className="flex justify-between w-[95%] m-auto pt-[12px]">
            <p className="font-semibold text-[14px] leading-[16.41px] text-[#717171]">
              Price:
            </p>
            <p className="text-[14px] leading-[16.41px] text-[#1D192B]">
              <span>&#8377;</span>
              {calculateTotalCartCost()?.toFixed(2)}/-
            </p>
          </div>
          <div className="flex justify-between w-[95%] m-auto pt-[12px] mt-auto">
            <p className="font-semibold text-[14px] leading-[16.41px] text-[#717171]">
              GST ({user?.gstPercentage}%):
            </p>
            <p className="text-[14px] leading-[16.41px] text-[#1D192B]">
              <span>&#8377;</span>
              {returnGstTotal()?.toFixed(2)}/-
            </p>
          </div>
          <div className="w-[100%] ml-auto border-b border-b-[#EAECF0] pt-[12px]"></div>
          <div className="flex justify-between items-center w-[95%] m-auto pt-[12px] pb-[12px]">
            <p className="font-semibold text-[14px] leading-[16.41px] text-[#1D192B]">
              Add discount:
            </p>
            {addDiscount ? (
              <div>
                <Input
                  placeholder="Discount.."
                  onChange={(e) => {
                    // const inputValue = e.target.value;
                    // const discountValue = parseInt(inputValue) || 0;
                    setDiscount(parseFloat(e.target.value));
                  }}
                  value={discount}
                  type="number"
                />
              </div>
            ) : null}
            {!addDiscount ? (
              <Button size="small" onClick={() => setAddDiscount(!addDiscount)}>
                +Add
              </Button>
            ) : (
              <Button
                size="small"
                onClick={() => {
                  setAddDiscount(false);
                  setDiscount(0);
                }}
              >
                -Remove
              </Button>
            )}
          </div>
          <div className="flex justify-between w-[95%] m-auto pt-[12px] pb-[12px]">
            <p className="font-semibold text-[14px] leading-[16.41px] text-[#1D192B]">
              Final Total:
            </p>
            <p className="font-semibold text-[14px] leading-[16.41px] text-[#1D192B]">
              <span>&#8377;</span>
              {addDiscount
                ? disCountCalculateTotalCartCost()?.toFixed(2)
                : removeDisCountCalculateTotalCartCost().toFixed(2)}
              /-
            </p>
          </div>
        </div>
        <p className="font-semibold text-[14px] leading-[16.41px] pl-[13px] mt-2">
          Payment method
        </p>
        <div className="flex gap-[8px] pt-[12px] pl-[13px]">
          <p
            className={`cursor-pointer border-[1px] px-[16px] py-[6px] border-solid border-[#717171] text-[14px] text-[#1D192B] rounded-[6px] ${
              cashMode === "CASH" ? "bg-[#674fa3] text-white" : ""
            }`}
            onClick={() => setCashMode("CASH")}
          >
            Cash
          </p>
          <p
            className={`cursor-pointer border-[1px] px-[16px] py-[6px] border-solid border-[#717171] text-[14px] text-[#1D192B] rounded-[6px] ${
              cashMode === "UPI" ? "bg-[#674fa3] text-white" : ""
            }`}
            onClick={() => setCashMode("UPI")}
          >
            UPI
          </p>
          <p
            className={`cursor-pointer border-[1px] px-[16px] py-[6px] border-solid border-[#717171] text-[14px] text-[#1D192B] rounded-[6px] ${
              cashMode === "CARD" ? "bg-[#674fa3] text-white" : ""
            }`}
            onClick={() => setCashMode("CARD")}
          >
            Credit/Debit Card
          </p>
          <p
            className={`cursor-pointer border-[1px] px-[16px] py-[6px] border-solid border-[#717171] text-[14px] text-[#1D192B] rounded-[6px] ${
              cashMode === "NET BANKING" ? "bg-[#674fa3] text-white" : ""
            }`}
            onClick={() => setCashMode("NET BANKING")}
          >
            Net Banking
          </p>
        </div>
        <div className="mt-[15px]">
          <div className="py-[16px] flex gap-[12px] text-[16px] flex-wrap justify-center">
            {isHome && (
              <button
                className="bg-[#674FA3] flex gap-[8px] items-center rounded-[60px] leading-[20px] h-[38px] px-[28.5px] text-white"
                onClick={() => {
                  if (cart.length === 0) {
                    alert("No items in Cart");
                    return false;
                  }
                  navigation("/tables");
                }}
              >
                <MdTableBar className="text-[16px]" /> Add To Table
              </button>
            )}
            <button
              className="bg-[#674FA3] flex gap-[8px] items-center rounded-[60px] leading-[20px] h-[38px] px-[28.5px] text-white"
              onClick={() => handlePrintReceipt("Save")}
              disabled={saving}
            >
              <FaReceipt className="text-[16px]" /> Save
            </button>

            <button
              className="bg-[#4CAA54] flex items-center gap-[8px] rounded-[60px] leading-[20px] h-[38px] px-[28.5px] text-white"
              onClick={() => handlePrintReceipt("SavePrint")}
              disabled={saving}
            >
              <BiPrinter className="text-[16px]" /> Save + Print
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
