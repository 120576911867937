import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blueToothDevice: null,
  usbDevice: null,
};

const printerDeviceSlice = createSlice({
  name: "printerDevices",
  initialState,
  reducers: {
    setBluetoothDevice: (state, action) => {
      state.blueToothDevice = action.payload;
    },
    setUsbDeviceR: (state, action) => {
      state.usbDevice = action.payload;
    },
  },
});

export const { setBluetoothDevice, setUsbDeviceR } = printerDeviceSlice.actions;
export default printerDeviceSlice.reducer;
