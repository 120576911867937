import React, { useState } from "react";
import logo from "../assets/logo.png";
import {
  Avatar,
  Box,
  Button,
  Fade,
  Menu,
  MenuItem,
  Modal,
} from "@mui/material";
import { BiPrinter } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  setDevices,
  setOpenConnecteModal,
} from "../redux/slices/bluetoothSlice";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { Link, useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { MdHome, MdLogout } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import {
  setBluetoothDevice,
  setUsbDeviceR,
} from "../redux/slices/printerDevicesSlice";
const Header = ({ setBluetoothCharacteristic, setUsbDevice }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const user = useSelector((state) => state.user.user);
  const cart = useSelector((state) => state.cart.cart);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openConnectModal = useSelector(
    (state) => state.bluetooth.openConnectModal
  );
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //   blutooth connection and printing
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    border: "none",
    "@media (max-width: 600px)": {
      width: "95%",
      height: "auto",
    },
  };
  const handleOpenConnectModal = () => {
    dispatch(setOpenConnecteModal(true));
  };
  const handleConnectModal = () => {
    dispatch(setOpenConnecteModal(false));
  };

  const handleBluetooth = async () => {
    try {
      const device = await navigator.bluetooth.requestDevice({
        acceptAllDevices: true,
        optionalServices: ["000018f0-0000-1000-8000-00805f9b34fb"],
      });
      dispatch(setDevices(device));

      const server = await device.gatt.connect();
      const service = await server.getPrimaryService(
        "000018f0-0000-1000-8000-00805f9b34fb"
      );
      const characteristic = await service.getCharacteristic(
        "00002af1-0000-1000-8000-00805f9b34fb"
      );
      console.log(server, service, characteristic);
      dispatch(() => setBluetoothCharacteristic(characteristic));
      dispatch(setBluetoothDevice(characteristic));

      setBluetoothCharacteristic(characteristic);
      handleConnectModal();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUSB = async () => {
    try {
      // Request a USB device with no specific filters
      const usbDevice = await navigator.usb.requestDevice({ filters: [] });
      // Open the selected device
      await usbDevice.open();
      await usbDevice.selectConfiguration(
        usbDevice.configurations[0].configurationValue
      );
      // Claim the first interface
      await usbDevice.claimInterface(
        usbDevice.configuration.interfaces[0].interfaceNumber
      );
      dispatch(setUsbDeviceR(usbDevice));
      setUsbDevice(usbDevice);
      handleConnectModal();
    } catch (error) {
      console.error("Error selecting USB device:", error);
    }
  };
  return (
    <div className="w-full h-[64px] border-b-[1px] border-solid border-[#EAECF0]">
      <Modal
        open={openConnectModal}
        onClose={handleConnectModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex items-center justify-center">
            <Button onClick={handleUSB}>USB</Button>
            <Button onClick={handleBluetooth}>Bluetooth</Button>
            <Button>Serial</Button>
          </div>
        </Box>
      </Modal>
      <div className="w-[95%] m-auto flex justify-between py-[12px]">
        <Link to="/home">
          <img src={logo} alt="logo" />
        </Link>
        <div className="flex gap-[24px]">
          <button
            className="bg-[#674FA3] rounded-[60px] px-[20px] flex gap-2 items-center font-semibold text-white"
            onClick={handleOpenConnectModal}
          >
            <BiPrinter size={20} />
            Connect to printer
          </button>
          <Avatar
            src={user?.profilePic}
            onClick={handleClick}
            className="cursor-pointer"
            alt="Logo"
          />
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="border-[#EAECF0] border-[1.5px] border-solid !w-[240px]"
          >
            <MenuItem onClick={handleClose} className="w-[240px]">
              <Link className="flex gap-[18px] items-center" to="/profile">
                <FaUser size={18} />
                <p className="text-[14px]">Profile</p>
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose} className="w-[240px]">
              <div className="flex gap-[18px] items-center">
                <MdHome size={18} />
                <p className="text-[14px]">My Shop</p>
              </div>
            </MenuItem>
            <MenuItem onClick={handleLogout} className="w-[240px]">
              <div className="flex gap-[18px] items-center">
                <MdLogout size={18} />
                <p className="text-[14px]">Logout</p>
              </div>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Header;
